import { Row } from "react-onsenui";
import { payInvoice, GetUserIdentifiers } from "../../api/NPE";
import formatNumber from "../../utils/formatNumber";
import axios from "../../utils/Axios";
import { FaSpinner } from "react-icons/fa";
import { useState } from "react";

const NpeStep2 = ({ npeInfo, setNpeStep, setCode, code }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const ident = await GetUserIdentifiers();
    const identifier = ident.data.filter(
      (el) => el.IdentifierType === "legal rep dui"
    )[0].Identifier;
    const res = await payInvoice({
      codigo: code,
      tipoCodigo: "NPE",
      numeroDocumento: identifier,
      tipoDocumento: "DUI",
      senderAccountId: parseInt(localStorage.getItem("m_acct_id")),
      productId: 1,
      amount: npeInfo.montoPagar,
      notice: "",
    });
    setLoading(false);
    if (res.result) {
      setNpeStep(3);
    }
  };
  return (
    <div className="payment-step step2-margin">
      <div className="message">Monto de factura</div>
      <div
        style={{
          marginBottom: "1rem",
          height: "29px",
          padding: "8px",
          fontSize: "1.2rem",
        }}
        className="btn-amount"
      >{`${npeInfo.montoPagar} USD`}</div>
      <Row className="amount-send">
        <div
          style={{
            fontSize: "1rem",
            height: "40px",
            color: "black",
            backgroundColor: "#FFFFFF",
          }}
          className="amount-money"
        >
          {`A ${npeInfo.nombreColector}`}
        </div>
      </Row>
      <Row className="amount-send">
        <div
          style={{
            fontSize: "1rem",
            height: "40px",
            color: "grey",
            backgroundColor: "#D5D9E9",
          }}
          className="amount-money"
        >
          {`Recibo de enero 2022`}
        </div>
      </Row>
      <Row className="amount-send">
        <div
          onClick={() => {
            setNpeStep(1);
            setCode("");
          }}
          style={{
            fontSize: "1rem",
            height: "40px",
            color: "black",
            backgroundColor: "#E6E6E6",
          }}
          className="amount-money"
        >
          Anterior
        </div>
        <div
          onClick={handleSubmit}
          style={{
            fontSize: "1rem",
            height: "40px",
            color: "white",
            backgroundColor: "#010D4C",
          }}
          className="amount-money"
        >
          Confirmar
        </div>
      </Row>
      {loading && (
        <FaSpinner
          style={{ marginTop: "10px" }}
          size={20}
          className={`icon-spin loader-blue`}
        />
      )}
    </div>
  );
};

export default NpeStep2;
