/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import AddAmount from "./AddAmount";
import SuccessExchange from "./SuccessExchange";
import { Row } from "react-onsenui";

const ExchangeBalance = () => {
  const [step, setStep] = useState("");
  const [amount, setAmount] = useState(0);
  return (
    <Row className="exchange-content">
      {step === "success" ? (
        <SuccessExchange setStep={setStep} amount={amount} />
      ) : (
        <AddAmount setStep={setStep} amount={amount} setAmount={setAmount} />
      )}
    </Row>
  );
};

export default ExchangeBalance;
