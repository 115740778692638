import React, { useState } from "react";
import { Button, Row } from "react-onsenui";
import { UseFilters } from "../../context/Filters";
import NpeStep1 from "./NpeStep1";
import NpeStep2 from "./NpeStep2";
import NpeStep2Unique from "./NpeStep2Unique";
import NpeStep3 from "./NpeStep3";

const Index = () => {
  const { npeStep, setNpeStep, npeInfo, setNpeInfo, amount, setAmount } = UseFilters();
  const [code, setCode] = useState("");
  return (
    <div className="process-payments">
      <div className="button-section">Pagar Impuestos</div>
      <div className="text-info">Ingresa la información requerida</div>
      <Row className="row-steps">
        <div
          className={npeStep === 1 ? "back-blue option-step" : "option-step"}
        >
          NPE Ingresa Codigo
        </div>
        <div
          className={
            npeStep === 2 || npeStep === 2.5
              ? "back-blue option-step"
              : "option-step"
          }
        >
          Pagar Monto
        </div>
        <div
          className={npeStep === 3 ? "back-blue option-step" : "option-step"}
        >
          Confirmar NPE
        </div>
      </Row>
      {npeStep === 1 && (
        <NpeStep1
          code={code}
          setCode={setCode}
          setNpeInfo={setNpeInfo}
          setNpeStep={setNpeStep}
        />
      )}
      {npeStep === 2 && (
        <NpeStep2
          setCode={setCode}
          npeStep={npeStep}
          npeInfo={npeInfo}
          code={code}
          setNpeStep={setNpeStep}
        />
      )}
      {npeStep === 2.5 && (
        <NpeStep2Unique
          setCode={setCode}
          code={code}
          npeStep={npeStep}
          npeInfo={npeInfo}
          setNpeStep={setNpeStep}
          amount={amount}
          setAmount={setAmount}
        />
      )}
      {npeStep === 3 && (
        <NpeStep3
          setCode={setCode}
          code={code}
          npeStep={npeStep}
          npeInfo={npeInfo}
          setNpeInfo={setNpeInfo}
          setNpeStep={setNpeStep}
          amount={amount}
          setAmount={setAmount}
        />
      )}
    </div>
  );
};

export default Index;
