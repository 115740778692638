import moment from "moment";
import React, { useState } from "react";
import Datepicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Select, Button, Modal } from "react-onsenui";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { FaRegCalendarAlt, FaRegCheckCircle } from "react-icons/fa";
import Loader from "../../components/Loader";
import { export2CSV } from "../../utils/export2csv";
import axios from "../../utils/Axios";
import { movementTypeValues } from "../Movements/constants";
import { accountIdSelector } from "../../redux/selectors";
import { logout } from "../../redux/Auth/auth.actions";
import { unixToTicks } from "../../utils/unixToTicks";
import translationMovements from "../../utils/translationMovements";

const DashboardExport = ({ isOpen, loading, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const AccountName = useSelector(state => {
    if (state.auth?.user?.UserProfileType === "MerchantId") {
      return state?.auth?.user?.AccountName;
    }
    return state?.auth?.user?.MerchantName;
  });

  const NIT = useSelector(state => {
    if (state.auth?.user?.UserProfileType === "MerchantId") {
      return state?.auth?.user?.Identifier;
    }
    return state?.auth?.user?.MerchantIdentifier;
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const AccountId = useSelector(accountIdSelector);
  const [isExcel, setIsExcel] = useState(false);

  const CustomInput = ({ onClick, label, value, className }) => (
    <Button
      onClick={onClick}
      className={className}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "0px"
      }}>
      <FaRegCalendarAlt
        className="dateIcon"
        style={{ marginTop: "0px", marginRight: "4px" }}
      />
      {value || label}
      <div className="arrow-down" />
    </Button>
  );

  const downloadFile = async () => {
    let Description = type;
    if (type === "Pay" || type === "purchase") {
      Description = "Purchase";
    }

    let start = new Date(startDate);
    let end = new Date(endDate);

    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountTransactionDetailHistory`,
        {
          OMSId: 1,
          AccountId,
          Description,
          Depth: 2147483647,
          StartTimestamp:
            (startDate && unixToTicks(start.getTime())) || undefined,
          EndTimestamp: (endDate && unixToTicks(end.getTime())) || undefined
        }
      );

      if (data.length) {
        const totals = {
          TimeStamp: t("Total")
        };

        const transformedData = data.reduce((acc, i) => {
          if (!totals.Amount) {
            totals.Amount = i.Amount;
          } else {
            totals.Amount += i.Amount;
          }
          if (!totals.NotionalAmount) {
            totals.NotionalAmount = i.NotionalAmount;
          } else {
            totals.NotionalAmount += i.NotionalAmount;
          }

          acc.push({
            TimeStamp: moment(i.TimeStamp).format("MMMM D YYYY h:mm:ss a"),
            Description: i.Description,
            Product: i.ProductId === 1 ? "USD" : "BTC",
            From: i.From,
            To: i.To,
            Amount: i.Amount,
            Balance: i.Balance,
            Status: i.Status,
            TransactionId: i.TransactionId,
            AccountId: i.AccountId,
            CounterpartyAccountId: i.CounterpartyAccountId,
            Details: i.Details,
            TransactionRefType: i.TransactionRefType,
            TransactionRefId: i.TransactionRefId,
            TransactionType: i.TransactionType,
            NotionalAmount: i.NotionalAmount,
            NotionalProductId: i.NotionalProductId,
            ConversionDetails: i.ConversionDetails
          });
          return acc;
        }, []);

        transformedData.push(totals);
        transformedData.forEach(x => {
          Object.keys(x).forEach(key => {
            if (key in translationMovements) {
              x[translationMovements[key]] = x[key];
              delete x[key];
            }
          });
        });

        return export2CSV(
          transformedData,
          t("Movimientos"),
          csvString => {
            const header =
              "CHIVO WALLET | RESUMEN DE OPERACIONES,,,,,,,,,,,,,,,,,,,,,,\r\n";
            const header2 =
              "CONTRIBUYENTE:," + AccountName + ",NIT:," + NIT + ",";

            let extraCommas = ",,,,,,,,,,,,,,,,,,\r\n";
            if (startDate) {
              if (!endDate) {
                extraCommas =
                  "PERIODO:," +
                  startDate.toLocaleString("es") +
                  " a " +
                  new Date().toLocaleString("es") +
                  ",,,,,,,,,,,,,,,,,\r\n";
              } else {
                extraCommas =
                  "PERIODO:," +
                  startDate.toLocaleString("es") +
                  " a " +
                  endDate.toLocaleString("es") +
                  ",,,,,,,,,,,,,,,,,\r\n";
              }
            }

            return header + header2 + extraCommas + csvString;
          },
          isExcel
        );
      } else {
        NotificationManager.error(t("No hay Movimientos"));
      }
    } catch (error) {
      NotificationManager.error(t("Ocurrió un error"));
      if (error.response.status === 404) {
        dispatch(logout());
      }
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="card dialog-center">
        <div className="card__content flex-center flex-col">
          <h2 className="card__title">Generar Reporte</h2>
          <h4 className="card__title">
            Selecciona el rango de fechas que deseas imprimir en tu reporte
          </h4>
          <div className="transactions movements">
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <div
                className="button-blue select-type"
                style={{ marginTop: "0px" }}>
                <FaRegCheckCircle className="type-icon" />
                <Select
                  value={type}
                  onChange={e => {
                    setType(e.target.value);
                  }}>
                  <option value="">{t("Tipo")}</option>
                  {Object.keys(movementTypeValues).map((key, index) => (
                    <option key={index} value={key}>
                      {t(`${movementTypeValues[key].label}`)}
                    </option>
                  ))}
                </Select>
              </div>
              <Datepicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                maxDate={endDate || moment.now()}
                dateFormat="dd/MM/yyyy"
                className="middle-picker"
                showTimeSelect
                customInput={
                  <CustomInput label="Desde" className="button-blue" />
                }
              />
              <Datepicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                minDate={startDate}
                maxDate={moment.now()}
                dateFormat="dd/MM/yyyy"
                showTimeSelect
                customInput={
                  <CustomInput label={t("Hasta")} className="button-blue" />
                }
              />
            </div>
          </div>
          {/* <Col width="18%">
            <div className="form-col-input" style={{
              background: "#283090",
              color: "white",
              justifyContent: 'space-around',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
              fontSize: '15px',
              height: '40px',
              padding: '0px',
            }}>
              Para Excel?
              <Checkbox 
                modifier="noborder"
                className="status-icon white-check"
                style={{ margin: '0', color: 'white', border: '2px solid white', fontWeight: '600', height: '18px', width: '18px' }}
                onChange={() => setIsExcel(!isExcel)}
              />
            </div>
          </Col> */}
          <Button
            style={{ margin: "0px", cursor: "pointer", marginTop: "10px" }}
            className="button-blue inline-button text-align-center"
            disabled={loading}
            onClick={() => {
              downloadFile();
              setIsOpen(false);
            }}>
            {loading ? <Loader /> : "Ok"}
          </Button>
          <Button
            className="button-blue inline-button text-align-center"
            onClick={() => setIsOpen(false)}
            style={{ cursor: "pointer" }}>
            {t("Cancelar")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DashboardExport;
