const formatNumber = (number) => {
  return Number(number).toLocaleString("en", {
    maximumFractionDigits: 8,
    minimumFractionDigits: 8,
  });
};

export const formatUSDNumber = number => {
  return Number(number).toLocaleString("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }); 
}

// regex to limit amount of character in the string of numbers/also to add , in between every 3 digits
export const formatAmount = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export default formatNumber;