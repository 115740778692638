import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/Loader";
import axios from "../../utils/Axios";
import { accountIdSelector } from "../../redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { BANKS } from "../Settings/banks";
import { formatUSDNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { logout } from "../../redux/Auth/auth.actions";

const ConfirmWithdraw = ({ setStep, payload, account }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const AccountId = useSelector(accountIdSelector);
  const [msj] = useState(t("Ha ocurrido un error"));
  const dispatch = useDispatch();

  const onConfirm = async () => {
    setLoading(true);

    try {
      let json;
      json = JSON.parse(payload.AccountIdentifier);
      json.BankCode = BANKS.find(i => i.name === payload.Name).code;
      if (json.DestinationAccountNumber) {
        json.DestinationAccountNumber = json.DestinationAccountNumber.trim();
      }

      const { data } = await axios.post(
        `${window.config.serverUri}CreateWithdrawTicket`,
        {
          OMSId: 1,
          ProductId: 1,
          AccountId,
          Amount: payload.amount,
          TemplateType: "BancoHipotecario",
          AccountProviderId: window.config.BHAccountProviderId,
          TemplateForm: JSON.stringify(json),
        }
      );

      if (data.result) {
        setStep("success");
      } else {
        NotificationManager.error(msj);
      }
    } catch (error) {
      NotificationManager.error(msj);
      if (error?.response?.status === 404) {
        dispatch(logout());
      }
    } finally {
      setLoading(false);
    }
  };

  let selectedAccountData;
  if (account) {
    try {
      selectedAccountData = JSON.parse(account.AccountIdentifier);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="withdraw-card">
      <div className="withdraw-confirm-title">{t("Vas a retirar")}</div>
      <div className="withdraw-amount button-blue">
        ${formatUSDNumber(payload.amount)} USD
      </div>
      <div className="mv-3">
        <div className="withdraw-confirm-title">
          {t("A la cuenta terminada en")}
        </div>
        <div className="withdraw-confirm-account">
          ****{" "}
          {selectedAccountData?.DestinationAccountNumber?.slice(
            selectedAccountData?.DestinationAccountNumber?.length - 4,
            selectedAccountData?.DestinationAccountNumber?.length
          )}{" "}
          - {account?.Name}
        </div>
      </div>
      <div
        className={`withdraw-confirm-button action-btn pointer ${
          loading ? "loading" : ""
        }`}
        onClick={onConfirm}
      >
        <p>{loading ? <Loader /> : t("Confirmar")}</p>
      </div>
    </div>
  );
};

export default ConfirmWithdraw;
