import React, { useState } from "react";
import { Row } from "react-onsenui";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { FaSpinner } from "react-icons/fa";
import { getCodeInfo, GetUserIdentifiers } from "../../api/NPE";

const NpeStep1 = ({ code, setCode, setNpeStep, setNpeInfo }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (code.length === 0) {
      NotificationManager.error("Datos ingresados incorrectos");
      return;
    }
    setLoading(true);
    const ident = await GetUserIdentifiers();
    const fecha = moment().format("YY-MM-DD");
    const hora = moment().format("HH:mm:ss");
    const identifier = ident.data.filter(
      (el) => el.IdentifierType === "legal rep dui"
    )[0].Identifier;
    const requestBody = {
      fecha,
      hora,
      Caja: "aaa",
      IdTienda: "",
      NomTienda: "",
      TipoDocumento: "DUI",
      NumeroDocumento: identifier,
      tipoCodigo: "NPE",
      codigo: code,
    };

    const npeInfo = await getCodeInfo(requestBody);
    setLoading(false);
    if (npeInfo.result) {
      NotificationManager.success("Código es válido");
      setNpeInfo(npeInfo);
      if (npeInfo.tPago === "F") {
        setNpeStep(2);
      } else if (npeInfo.tPago === "V") { 
        setNpeStep(2.5);
      }
    } else {
      NotificationManager.error("Datos ingresados incorrectos");
    }
  };

  return (
    <div className="payment-step">
      <div className="message">Ingresa Codigo de NPE</div>
      <Row className="amount-send">
        <input
          onChange={(e) => setCode(e.target.value)}
          style={{ height: "40px", color: "black" }}
          className="amount-money"
          onKeyDown={(e) => {
            e.key === "Enter" && handleSubmit();
          }}
        ></input>
      </Row>
      <Row className="amount-send">
        <div
          onClick={handleSubmit}
          style={{ height: "40px", color: "white", backgroundColor: "#010D4C" }}
          className="amount-money"
        >
          Siguiente
        </div>
      </Row>
      {loading && (
        <FaSpinner
          style={{ marginTop: "10px" }}
          size={20}
          className={`icon-spin loader-blue`}
        />
      )}
    </div>
  );
};

export default NpeStep1;
