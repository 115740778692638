import { Card, Col, Row } from "react-onsenui";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ios from "../../../assets/images/1280px-Download_on_the_App_Store_Badge_ES_RGB_blk.svg.png";
import playstore from "../../../assets/images/googleimage.png";
import appgal from "../../../assets/images/appgallery-es.png";

function SignUp() {
  const { t } = useTranslation();
  return (
    <div className="auth-container">
      <Row className="auth-navbar">
        <Link to="/login" className="link header-link pointer">
          <div className="auth-navbar-item">{t("Iniciar sesión")}</div>
        </Link>
        <Link to="register" className="link header-link pointer">
          <div className="auth-navbar-item">{t("Regístrate")}</div>
        </Link>
      </Row>
      <Row className="auth-form-box">
        <Card className="card-auth bckWhite">
          <div>
            <Row
              className="justify-content-center"
              style={{ marginTop: "50px" }}
            >
              <img src={logo} alt="Logo" className="logo-auth" />
            </Row>
          </div>
          <div className="margin-auth">
            <Row className="justify-content-center text-align-center">
              <Col width="62%" style={{ marginBottom: "50px" }}>
                <div className="title-register">{t("Regístrate")}</div>
                <div className="subtitle-register">
                  {t("Dirígete a nuestra app para realizar el registro.")}
                </div>
                <a href="https://play.google.com/store/apps/details?id=com.chivo.wallet" target="_blank">
                  <img src={playstore} alt="Google Play Store" className="img-store" style={{width: "100%", height: "22%"}} />
                </a>
                <a href="https://apps.apple.com/us/app/chivo-wallet/id1581515981" target="_blank">
                  <img src={ios} alt="Apple App Store" className="img-store" style={{width: "100%", height: "22%"}} />
                </a>
                <a href="https://appgallery.huawei.com/app/C104652823" target="_blank">
                  <img src={appgal} alt="Huawei App Gallery" className="img-store" style={{width: "100%", height: "22%"}} />
                </a>
              </Col>
            </Row>
          </div>
        </Card>
      </Row>
    </div>
  );
}

export default SignUp;
