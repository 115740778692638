export const BANKS = [
  {
    id: 1,
    active: true,
    name: 'Banco Agrícola, S.A.',
    min: 10,
    max: 12,
    code: "006"
  },
  {
    id: 2,
    active: true,
    name: 'Banco Davivienda',
    min: 8,
    max: 12,
    code: "011"
  },
  {
    id: 3,
    active: true,
    name: 'Banco Hipotecario, S.A. De CV.',
    min: 11,
    max: 11,
    code: "012"
  },
  {
    id: 4,
    active: true,
    name: 'Banco De Fomento Agropecuario',
    min: 13,
    max: 13,
    code: "018"
  },
  {
    id: 5,
    active: true,
    name: 'Banco G&T Continental De El Salvador, S.A.',
    min: 15,
    max: 15,
    code: "017"
  },
  {
    id: 6,
    active: true,
    name: 'Banco Promerica, S.A.',
    min: 14,
    max: 14,
    code: "013"
  },
  {
    id: 7,
    active: true,
    name: 'Banco de America Central, S.A',
    min: 9,
    max: 9,
    code: "015"
  },
  {
    id: 8,
    active: true,
    name: 'Banco Industrial El Salvador, S.A.',
    min: 12,
    max: 12,
    code: "048"
  },
  {
    id: 9,
    active: true,
    name: 'Banco Atlantida S.A.',
    min: 13,
    max: 13,
    code: "029"
  },
  {
    id: 10,
    active: true,
    name: 'Banco Cuscatlan',
    min: 15,
    max: 15,
    code: "008"
  },
  {
    id: 11,
    active: true,
    name: 'FEDECREDITO',
    min: 12,
    max: 13,
    code: "034"
  }
];
