import { Col, Row, Button } from "react-onsenui";
import Loader from "../../../components/Loader";
import { IoIosArrowBack } from "react-icons/io";
import PhoneNumberInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";

const PhoneInput = ({ phone, setPhone, loading, onSendOTP, setType }) => {
  const { t } = useTranslation();
  const lang = t("lang");
  return (
    <>
      <div className="margin-auth">
        <Row className="justify-content-center text-align-center margin-auth-item">
          <Col style={{ marginTop: "20px" }} width="70%">
            <Row>
              <PhoneNumberInput
                inputClass="phone-input"
                specialLabel=""
                localization={lang === "es" ? es : ""}
                searchPlaceholder={"Buscar"}
                countryCodeEditable={false}
                enableSearch={true}
                country={"sv"}
                preferredCountries={["sv", "us"]}
                onlyCountries={[
                  "sv",
                  "us",
                  "ca",
                  "mx",
                  "bz",
                  "gt",
                  "hn",
                  "ni",
                  "cr",
                  "pa",
                  "es",
                  "it",
                  "au",
                  "se",
                  "de",
                  "co",
                  "al",
                  "ag",
                  "ar",
                  "am",
                  "at",
                  "bs",
                  "bh",
                  "bb",
                  "by",
                  "be",
                  "bj",
                  "bm",
                  "bt",
                  "bo",
                  "ba",
                  "bw",
                  "br",
                  "io",
                  "bg",
                  "bf",
                  "bi",
                  "kh",
                  "cm",
                  "cv",
                  "ky",
                  "cf",
                  "cl",
                  "km",
                  "ck",
                  "hr",
                  "cy",
                  "cz",
                  "dk",
                  "dj",
                  "dm",
                  "do",
                  "cd",
                  "tl",
                  "ec",
                  "eg",
                  "gq",
                  "er",
                  "ee",
                  "et",
                  "fo",
                  "fj",
                  "fi",
                  "fr",
                  "gf",
                  "pf",
                  "ga",
                  "gm",
                  "ge",
                  "gi",
                  "gr",
                  "gl",
                  "gr",
                  "gd",
                  "gp",
                  "gu",
                  "gg",
                  "gn",
                  "gw",
                  "gy",
                  "hk",
                  "hu",
                  "is",
                  "id",
                  "ie",
                  "jm",
                  "jp",
                  "je",
                  "jo",
                  "ke",
                  "ki",
                  "kw",
                  "la",
                  "lv",
                  "lb",
                  "li",
                  "lt",
                  "lu",
                  "mo",
                  "mk",
                  "mg",
                  "mw",
                  "my",
                  "mv",
                  "ml",
                  "mt",
                  "mh",
                  "mq",
                  "mr",
                  "mu",
                  "fm",
                  "md",
                  "mc",
                  "me",
                  "ms",
                  "ma",
                  "mz",
                  "na",
                  "np",
                  "nl",
                  "an",
                  "nc",
                  "nz",
                  "ne",
                  "nf",
                  "mp",
                  "no",
                  "om",
                  "pw",
                  "pg",
                  "py",
                  "pe",
                  "pl",
                  "pt",
                  "pr",
                  "qa",
                  "re",
                  "ro",
                  "rw",
                  "ws",
                  "sm",
                  "st",
                  "sa",
                  "sn",
                  "rs",
                  "sc",
                  "sk",
                  "si",
                  "sb",
                  "za",
                  "lk",
                  "kn",
                  "lc",
                  "pm",
                  "vc",
                  "sd",
                  "sr",
                  "sz",
                  "ch",
                  "tw",
                  "tg",
                  "to",
                  "tt",
                  "tn",
                  "tr",
                  "tc",
                  "tv",
                  "ug",
                  "ua",
                  "ae",
                  "gb",
                  "uy",
                  "vu",
                  "vn",
                  "vg"
                ]}
                value={phone}
                onChange={phone => setPhone(`+${phone}`)}
                type="submit"
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    onSendOTP();
                  }
                }}
                inputProps={{
                  required: true,
                  autoFocus: true
                }}
              />
            </Row>
            <Button
              onClick={() => onSendOTP()}
              modifier="large--cta"
              id="btn-send-otp"
              className="rounded main-bg pointer margin-auth"
              disabled={loading}>
              <div className="btn-login margin-null">
                {loading ? <Loader /> : t("Enviar código")}
              </div>
            </Button>
            <Button
              modifier="quiet"
              className="transparent-btn pointer"
              onClick={() => {
                setPhone(null);
                setType(null);
              }}>
              <Row className="btn-back justify-content-center align-items-center">
                <IoIosArrowBack /> {t("Regresar")}
              </Row>
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PhoneInput;
