import React from "react";
import { typeStyle } from "../Movements/constants";
import { Link, useHistory } from "react-router-dom";


const NpeStep3 = ({ npeInfo, setNpeInfo, setNpeStep, setCode, amount, setAmount }) => {
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="message"
        style={{ fontSize: "2rem", color: "black ", marginBottom: "15px" }}
      >
        <b>¡Éxito!</b>
      </div>
      <div
        className="status-mark"
        style={{ color: typeStyle["approved"]?.color, fontSize: "4rem" }}
      >
        {typeStyle["approved"]?.icon}
      </div>
      <div style={{ fontSize: "1.2rem", color: "grey" }} className="message">
        <b>Pagaste</b>
      </div>
      <div
        style={{ fontSize: "3.5rem", marginBottom: "15px" }}
        className="message"
      >
        <b>{`$${npeInfo.tPago === "V" ? amount : npeInfo.montoPagar}`}</b>
      </div>
      <div
        style={{
          fontSize: "1.2rem",
          padding: "10px",
          backgroundColor: "#283090",
          color: "white",
          width: "10%",
          borderRadius: "15px",
        }}
        className="message"
      >
        <b>USD</b>
      </div>
      <div
        style={{ fontSize: "1.2rem", marginTop: "15px" }}
        className="message"
      >
        <b>{`a ${npeInfo.nombreColector}`}</b>
      </div>
      <div
        style={{
          fontSize: "1.2rem",
          padding: "10px",
          backgroundColor: "#010C4C",
          color: "white",
          width: "50%",
          borderRadius: "15px",
          marginTop: "50px",
        }}
        onClick={() => {
          setNpeStep(1);
          setNpeInfo({});
          setCode("");
          setAmount("");
          history.push("/dashboard");
        }}
        className="message"
      >
        <b>OK</b>
      </div>
    </div>
  );
};

export default NpeStep3;
