import { useEffect, useState } from "react";
import { Card, Row } from "react-onsenui";
import logo from "../../../assets/images/logoV2.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirmLogin, requestOTP, verifyOTP, checkRefreshToken, authenticateApiKey, AuthenticateMerchantPOSUser } from "../../../redux/Auth/auth.actions";
import { LOGIN } from "../../../redux/Auth/auth.types";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import TypeSelector from "./TypeSelector";
import PhoneInput from "./PhoneInput";
import OTPInput from "./OTPInput";
import { NotificationManager } from "react-notifications";

const SignIn = () => {
  const { t } = useTranslation();
  const [dni, setDni] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [phone, setPhone] = useState("");
  const [step, setStep] = useState(1);
  const [OTP, setOTP] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    async function checktoken() {
      const data = await checkRefreshToken();
      if (data && data.UserId) {

        dispatch({
          type: LOGIN,
          payload: {
            ...data,
            logged: true
          },
        });
      }
    }
    checktoken();
  }, []);

  const onLogin = async () => {
    if (!loading) {
      setLoading(true);

      if (type === "POS") {
        const data = await dispatch(AuthenticateMerchantPOSUser(dni, password));
        setLoading(false);
      } else {
        try {
          const data = await dispatch(confirmLogin(phone, password, dni, OTP));
          if (data.APIKey) {
            await dispatch(authenticateApiKey(setLoading, data.UserId, data.APIKey, data.APISecret));
          } else if (data.result === false) {
            NotificationManager.error(t("Información de inicio de sesión no válida"));
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const onSendOTP = async () => {
    setLoading(true);

    try {
      const success = await requestOTP(phone);

      if (success) {
        NotificationManager.success(t("Se ha enviado un código de verificación a tu teléfono móvil."));
        setStep(2);
      } else {
        NotificationManager.error(t("No se pudo verificar el teléfono móvil."));
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoading(false);
  };

  const onVerifyOTP = async () => {
    setLoading(true);

    try {
      const success = await verifyOTP(phone, OTP);

      if (success) {
        NotificationManager.success(t("Verificación exitosa."));
        
        setStep(3);
      } else {
        NotificationManager.error(t("Código de verificación no válido"));
      }
    } catch (error) {
      console.log("error", error);
    }

    setLoading(false);
  };

  const onDelete = (e) => {
    if (e.keyCode === 8 && type === ACCOUNT_TYPES["commerce"]) {
      e.preventDefault();
      if (dni[dni.length - 1] === "-") {
        setDni(dni.slice(0, dni.length - 2));
      } else {
        setDni(dni.slice(0, dni.length - 1));
      }
    }
  };

  return (
    <div className="auth-container">
      <Row className="auth-navbar">
        <Link onClick={() => {window.location.reload();}} to="/login" className="link header-link pointer">
          <div className="auth-navbar-item">{t("Iniciar sesión")}</div>
        </Link>
        <Link to="register" className="link header-link pointer">
          <div className="auth-navbar-item">{t("Regístrate")}</div>
        </Link>
      </Row>
      <Row className="auth-form-box">
        <Card className="card-auth">
          <div>
            <Row className="justify-content-center">
              <img src={logo} alt="Logo" className="logo-auth" />
            </Row>
          </div>
          {!type ? (
            <TypeSelector setType={(value) => setType(value)} />
          ) : type === ACCOUNT_TYPES[t("commerce")] ? (
            step === 1 ? (
              <PhoneInput
                phone={phone}
                setPhone={(value) => setPhone(value)}
                loading={loading}
                onSendOTP={() => onSendOTP()}
                setType={(value) => setType(value)}
              />
            ) : step === 2 ? (
              <OTPInput
                OTP={OTP}
                setStep={setStep}
                setOTP={(value) => setOTP(value)}
                loading={loading}
                onVerifyOTP={() => onVerifyOTP()}
              />
            ) : (
              <Login
                dni={dni}
                setStep={setStep}
                setDni={(value) => setDni(value)}
                password={password}
                setPassword={(value) => setPassword(value)}
                loading={loading}
                onLogin={() => onLogin()}
                accountType={type}
                setType={(value) => setType(value)}
                onDelete={(e) => onDelete(e)}
              />
            )
          ) : (
            <Login
              dni={dni}
              setDni={(value) => setDni(value)}
              password={password}
              setPassword={(value) => setPassword(value)}
              loading={loading}
              onLogin={() => onLogin()}
              accountType={type}
              setType={(value) => setType(value)}
            />
          )}
        </Card>
      </Row>
    </div>
  );
};

export const ACCOUNT_TYPES = {
  commerce: "COMMERCE",
  pos: "POS",
};

export default SignIn;
