import { Col, Row, Button } from "react-onsenui";
import { ACCOUNT_TYPES } from ".";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import NumberFormat from "react-number-format";
import { validNIT } from "../../../utils/nit";

const Login = ({
  dni,
  setDni,
  password,
  setPassword,
  loading,
  onLogin,
  accountType,
  setType,
  onDelete,
  setStep
}) => {
  const { t } = useTranslation();

  const isSubmitDisabled = () => {
    if (accountType !== ACCOUNT_TYPES["pos"]) {
      if (!validNIT.test(dni)) return true;
    }
    return !(password?.length && dni?.length) || loading;
  };

  const resetState = () => {
    setDni(null);
    setPassword(null);
  };

  return (
    <>
      <div className="margin-auth">
        <Row className="justify-content-center text-align-center  margin-auth-item">
          <Col width="80%">
            <Row className="input-container">
              {accountType === ACCOUNT_TYPES["pos"] ? (
                <input
                  autoFocus
                  type="text"
                  className="text-input"
                  placeholder={t("Usuario")}
                  value={dni}
                  onChange={e => setDni(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onLogin();
                    }
                  }}
                />
              ) : (
                <NumberFormat
                  id="dni"
                  placeholder={t("NIT")}
                  autoFocus
                  className="text-input"
                  mask="#"
                  format="####-######-###-#"
                  onValueChange={vals => setDni(vals.value)}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onLogin();
                    }
                  }}
                />
              )}
            </Row>
            <Row className="input-container">
              <input
                type="password"
                id="password"
                className="text-input"
                placeholder="PIN"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    onLogin();
                  }
                }}
              />
            </Row>
            {/* <p
                  onClick={() => history.push("/")}
                  className="margin-auth pointer text-size"
                >
                  Olvidaste tu PIN de seguridad?
                </p> */}
            <Button
              onClick={() => onLogin()}
              modifier="large--cta"
              id="login"
              className="rounded main-bg pointer margin-auth"
              disabled={isSubmitDisabled()}>
              <div className="btn-login margin-null">
                {loading ? <Loader /> : t("Ingresar")}
              </div>
            </Button>
            {accountType !== ACCOUNT_TYPES["pos"] && (
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px"
                }}>
                <Button
                  modifier="quiet"
                  className="transparent-btn pointer"
                  style={{ color: "black" }}
                  onClick={() => {
                    resetState();
                    setStep(2);
                  }}>
                  <Row className="align-items-center">
                    <IoIosArrowBack /> {t("Regresar")}
                  </Row>
                </Button>
              </Row>
            )}
            {accountType === ACCOUNT_TYPES["pos"] && (
              <Button
                modifier="quiet"
                className="btn-back pointer"
                onClick={() => {
                  resetState();
                  setType(null);
                }}>
                <Row className="justify-content-center align-items-center">
                  <IoIosArrowBack /> {t("Regresar")}
                </Row>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
