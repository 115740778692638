import { MdCallMade, MdCallReceived, MdHistory } from "react-icons/md";
import { CgMoreO, CgCloseO } from "react-icons/cg";
import { FiRepeat } from "react-icons/fi";
import { AiOutlineGift } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";

export const getMovementTypeValue = mvmt => {
  if (movementTypeValues[mvmt.Description]) {
    return movementTypeValues[mvmt.Description];
  }
  if (mvmt.ReferenceType === "Transfer") {
    if (mvmt.DR > 0) {
      return movementTypeValues.Transfer_Send;
    } else {
      return movementTypeValues.Transfer_Receive;
    }
  }

  if (mvmt.ReferenceType === "Deposit") {
    return movementTypeValues.Deposit;
  }

  if (mvmt.ReferenceType === "Withdraw") {
    return movementTypeValues.Withdraw;
  }

  if (mvmt.ReferenceType === "Trade") {
    return movementTypeValues.Exchange;
  }

  if (mvmt.ReferenceType == "Reward") {
    return movementTypeValues.Gift;
  }
};

export const movementTypeValues = {
  Transfer_Send: {
    label: "Transferencia enviada",
    icon: <MdCallMade />
  },
  Transfer_Receive: {
    label: "Transferencia recibida",
    icon: <MdCallReceived />
  },
  Sale: {
    label: "Pago recibido",
    icon: <MdCallReceived />
  },
  Purchase: {
    label: "Pago enviado",
    icon: <MdCallMade />
  },
  Deposit: {
    label: "Depósito",
    icon: <MdCallReceived />
  },
  Withdraw: {
    label: "Retiro",
    icon: <MdCallMade />
  },
  "ATM Withdraw": {
    label: "Retiro ATM",
    icon: <MdCallMade />
  },
  "ATM Deposit": {
    label: "Depósito ATM",
    icon: <MdCallReceived />
  },
  purchase: {
    label: "Compra",
    icon: <MdCallMade />
  },
  Exchange: {
    label: "Convertiste",
    icon: <FiRepeat />
  },
  Gift: {
    label: "Recompensa",
    icon: <AiOutlineGift />
  }
};

export const getMovementTypeStyle = type => {
  if (typeStyle[type.Description]) {
    return typeStyle[type.Description];
  }
};

export const typeStyle = {
  Pending: {
    color: "#F15A24",
    icon: <MdHistory size={24} />,
    label: "En proceso"
  },
  Expired: {
    color: "#C1282E",
    icon: <CgCloseO />,
    label: "Rechazado"
  },
  Canceled: {
    color: " #C1282E",
    icon: <CgCloseO />,
    label: "Cancelado"
  },
  Paid: {
    color: "#00A000",
    icon: <FaRegCheckCircle />,
    label: "Aprobado"
  },
  PartialPayment: {
    color: "#FFC000",
    icon: <FaRegCheckCircle />,
    label: "Pago no se ajusta a la factura"
  },
  ////////
  initial: {
    color: "#F7C216",
    icon: <CgMoreO />,
    label: "Iniciando"
  },
  verifiying: {
    color: "#F7C216",
    icon: <CgMoreO />,
    label: "Verificando"
  },
  adjustment: {
    color: "#FFC000",
    icon: <FaRegCheckCircle />,
    label: "Pago no se ajusta a la factura"
  },
  sending: {
    color: "#F15A24",
    icon: <FaRegCheckCircle />,
    label: "En proceso"
  },
  approved: {
    color: "#00A000",
    icon: <FaRegCheckCircle />,
    label: "Aprobado"
  },
  Complete: {
    color: "#00A000",
    icon: <FaRegCheckCircle />,
    label: "Completo"
  },
  rejected: {
    color: " #C1282E",
    icon: <CgCloseO />,
    label: "Rechazado"
  },
  Failed: {
    color: " #C1282E",
    icon: <CgCloseO />,
    label: "Fallido"
  },
  refunded: {
    color: "#00FF00",
    icon: <FaRegCheckCircle />,
    label: "Reembolsado"
  },
  cancelled: {
    color: " #C1282E",
    icon: <CgCloseO />,
    label: "Cancelado"
  },
  Cancelled: {
    color: " #C1282E",
    icon: <CgCloseO />,
    label: "Cancelado"
  }
};
