export const accountIdSelector = (state) => {
  if (state.auth.user.ApexAccountId) {
    return state.auth.user.ApexAccountId;
  }
  if (state.auth.user.User) {
    return state.auth.user.User.AccountId;
  }
  return state.auth.user.AccountId;
};

export const userIdSelector = (state) => {
  if (state.auth.user.ApexUserId) {
    return state.auth.user.ApexUserId;
  }
  if (state.auth.user.User) {
    return state.auth.user.User.UserId;
  }
  return state.auth.user.UserId;
};

export const userEmailSelector = (state) => {
  if (state.auth.user.User) {
    return state.auth.user.User.UserId;
  }
  return state.auth.user.UserId;
};

export const userIsMerchantSelector = state => {
  return state.auth?.user?.UserProfileType === "MerchantId";
}

export const qrCodeNameSelector = state => {
  return state.auth?.user?.UserProfileType === "MerchantId" ?
    state.auth?.user?.AccountName
  : state?.auth?.user?.MerchantName;
}

export const userFullNameSelector = state => {
  return state.auth?.user?.FullName;
};