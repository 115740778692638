export const STATUS = [
  // { value: "initial", label: "Iniciando" },
  // { value: "verifiying", label: "Verificando" },
  // { value: "adjustment", label: "Ajustando" },
  // { value: "sending", label: "En proceso" },
  // { value: "approved", label: "Aprobado" },
  // { value: "rejected", label: "Rechazado" },
  // { value: "refunded", label: "Reembolsado" },
  // { value: "cancelled", label: "Cancelado" },

  //////
  { value: "Pending", label: "Iniciando" },
  { value: "Paid", label: "Aprobado" },
  { value: "Expired", label: "Rechazado" },
  { value: "Canceled", label: "Cancelado" },
  { value: "PartialPayment", label: "Pago no se ajusta a la factura" }
];
