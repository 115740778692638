import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "react-onsenui";
import chivoIcon from "../../assets/images/chivo_icon.png";
import bitcoinIcon from "../../assets/images/btc_icon.png";
import formatNumber from "../../utils/formatNumber";
import { getUsdNotionalRate } from "../../redux/ProcessPayment/processPayment.actions";
import { logout } from "../../redux/Auth/auth.actions";
import { useTranslation } from "react-i18next";
import { formatAmount } from "../../utils/formatNumber"

const Step2 = ({
  amount = "0",
  setSelectedMethod = e => e,
  selectedMethod = "",
  setBTCAmount = e => e
}) => {
  const { t } = useTranslation();
  const initialAmount = amount;
  const { availableCurrencies: currencies } = useSelector(
    state => state.paymentMethod
  );
  const dispatch = useDispatch();
  const [notionalRate, setNotionalRate] = useState(0);

  useEffect(() => {
    async function getRate() {
      try {
        const data = await getUsdNotionalRate();
        setNotionalRate(data);
      } catch (e) {
        if (e.response.status === 404) {
          dispatch(logout());
        }
      }
    }
    getRate();
  }, []);

  const selectMethod = (Product, amount) => {
    setSelectedMethod(Product);
    if (Product === "BTC") {
      setBTCAmount(amount / notionalRate);
    }
  };

  return (
    <div className="payment-step step2-margin">
      <div className="message">
        {t("Seleccione el método de pago a utilizar")}
      </div>
      <div className="btn-amount">{formatAmount(initialAmount)} USD</div>
      <Row className="payment-methods">
        {currencies.map((element, key) => {
          return (
            <Row
              key={key}
              id={`currency${key}`}
              className={
                selectedMethod === element.Product
                  ? "payment-options border-blue"
                  : "payment-options"
              }
              onClick={() => selectMethod(element.Product, amount)}
            >
              <img
                src={element.Product === "BTC" ? bitcoinIcon : chivoIcon}
                alt=""
                className="payment-icon"
              />
              <div className="text-box">
                <div className="payment-title">{element.ProductFullName}</div>
                <div className="payment-detail">
                  {element.Product !== "BTC"
                    ? t("Billetera")
                    : formatNumber(initialAmount / notionalRate)}
                </div>
              </div>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};

export default Step2;
