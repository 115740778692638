import Dashboard from "../views/Dashboard";
import Terminals from "../views/Terminals";
import Settings from "../views/Settings";
import ProcessPayments from "../views/ProcessPayments";
import PaymentConfirmation from "../views/PaymentConfirmation";
import SendMoney from "../views/SendMoney"
import Movements from "../views/Movements";
import ExchangeBalance from "../views/ExchangeBalance";
import GetPayment from "../views/GetPayment";
import Transactions from "../views/Transactions";
import MovementDetails from "../views/MovementDetails";
import Withdrawals from "../views/Withdrawals";
import Withdraw from "../views/Withdraw";
import Index from "../views/Npe/Index"

let dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/terminals", component: Terminals },
  { path: "/settings", component: Settings },
  { path: "/payments", component: ProcessPayments },
  { path: "/payment-confirmation", component: PaymentConfirmation },
  { path: "/send/friends", component: SendMoney },
  { path: "/movements", component: Movements },
  { path: "/exchange", component: ExchangeBalance },
  { path: "/get-payment", component: GetPayment },
  { path: "/transactions", component: Transactions },
  { path: "/details", component: MovementDetails },
  { path: "/withdrawals", component: Withdrawals },
  { path: "/withdraw", component: Withdraw },
  { path: "/npe", component: Index },
];

export default dashboardRoutes;
