/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Select, Button, Row } from "react-onsenui";
import Datepicker from "react-datepicker";
import { FaRegCalendarAlt, FaUndoAlt, FaSpinner } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import { CgCloseO, CgInfo, CgPrinter } from "react-icons/cg";
import DataTable from "react-data-table-component";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/Auth/auth.actions";
import { userIdSelector, userIsMerchantSelector } from "../../redux/selectors";
import axios from "../../utils/Axios";
import Export from "./Export";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Dialog from "../../components/Dialog";
import { navigateTrackTransaction } from "../../redux/ProcessPayment/processPayment.actions";
import Printer from "../../components/Printer";
import excelIcon from "../../assets/images/excel-icon.png";
import taxBtn from "../../assets/images/tax-btn.png";
import { STATUS } from "./constants";
import { UseFilters } from "../../context/Filters";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import Downshift from "downshift";
import ReactTooltip from "react-tooltip";
import { IconButton } from "@material-ui/core/";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { TablePagination } from "@material-ui/core";
import { checkEndDate } from "./helpers";

const PAGE_BASE = 0;

async function fetchMerchant({ MerchantId }) {
  const { data } = await axios.get(
    `${window.config.merchantServerUri}GetMerchant?MerchantId=${MerchantId}`
  );
  return data;
}

async function fetchMerchants({ userId }) {
  const { data } = await axios.get(
    `${window.config.merchantServerUri}GetMerchants?UserId=${userId}`
  );
  return data;
}

async function fetchInvoices({
  OMSId,
  StartIndex,
  Limit,
  startDate,
  endDate,
  MerchantId,
  selectedStatus,
  selectedTerminal
}) {
  return await axios.post(`${window.config.serverUri}GetInvoices`, {
    OMSId,
    Limit,
    StartIndex,
    StartDate:
      (startDate && moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss")) ||
      undefined,
    EndDate:
      (endDate && moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")) ||
      undefined,
    MerchantId,
    InvoiceStatus: selectedStatus,
    CreatedBy: parseInt(selectedTerminal)
  });
}

/** page counts from 0 */
function pageToOffset(page, size) {
  return page * size;
}

const Transactions = ({ history }) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedStatus,
    setSelectedStatus,
    selectedTerminal,
    setSelectedTerminal
  } = UseFilters();
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [disableGotoNext, setDisableGotoNext] = useState(false);
  const [page, setPage] = useState(PAGE_BASE);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [merchant, setMerchant] = useState(0);
  const userIsMerchant = useSelector(userIsMerchantSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);

  const userId = useSelector(userIdSelector);

  const resetPage = () => {
    setPage(PAGE_BASE);
  };

  const getTransactions = async (page, size) => {
    const offset = pageToOffset(page, size);
    const nextPageOffset = pageToOffset(page + 1, size);

    setIsLoading(true);

    try {
      let merchants;

      if (user.MerchantPOSUserId) {
        const data = await fetchMerchant({ MerchantId: user.MerchantId });
        merchants = [data];
        setMerchant(data.MerchantId);
      } else {
        const data = await fetchMerchants({ userId });
        merchants = data;
      }

      if (merchants && merchants.length) {
        const MerchantId = merchants[0].MerchantId;

        // TODO we can req 1 page x2 size and slice 50/50
        const { data: invoices } = await fetchInvoices({
          OMSId: 1,
          Limit: size,
          StartIndex: offset,
          startDate,
          endDate,
          MerchantId,
          selectedStatus,
          selectedTerminal
        });

        const nextInvoicesResponse = await fetchInvoices({
          OMSId: 1,
          Limit: size,
          StartIndex: nextPageOffset,
          startDate,
          endDate,
          MerchantId,
          selectedStatus,
          selectedTerminal
        });

        setTransactions(invoices);
        !nextInvoicesResponse.data.length
          ? setDisableGotoNext(true)
          : setDisableGotoNext(false);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);

      if (error?.response?.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(
          t("Ocurrió un error al cargar las transacciones")
        );
      }
    }
  };

  const getTerminals = async () => {
    try {
      const { data: merchants } = await axios.get(
        `${window.config.merchantServerUri}GetMerchants?UserId=${userId}`
      );

      if (merchants && merchants.length) {
        const MerchantId = merchants[0].MerchantId;
        setMerchant(MerchantId);
        const { data: pos } = await axios.get(
          `${
            window.config.merchantServerUri
          }GetMerchantPOSUsers?MerchantId=${MerchantId}`
        );

        if (Array.isArray(pos)) {
          setTerminals(pos);
        } else {
          setTerminals([]);
        }
        return;
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(
          t("Ocurrió un error al cargar las transacciones")
        );
      }
    }
  };

  useEffect(
    () => {
      getTerminals();
      getTransactions(page, pageSize);
    },
    [page, pageSize, startDate, endDate, selectedStatus, selectedTerminal]
  );

  const columns = [
    {
      name: t("Fecha"),
      selector: row => <DateCol date={row.CreatedDT} />,
      center: true
    },
    {
      name: t("Número de Factura"),
      selector: i => i.InvoiceId,
      center: true
    },
    {
      name: t("Monto a cobrar"),
      selector: row =>
        row.ProductId === 1
          ? formatUSDNumber(row.Amount)
          : formatNumber(row.Amount),
      center: true
    },
    {
      name: t("Monto pagado"),
      selector: row =>
        row.ProductId === 1
          ? formatUSDNumber(row.AmountReceived)
          : formatNumber(row.AmountReceived),
      center: true
    },
    {
      name: t("Monto nocional"),
      selector: row => formatUSDNumber(row.NotionalAmount),
      center: true
    },
    // {
    //   name: t("Monto nocional pagado"),
    //   selector: row => formatUSDNumber(row.NotionalAmountReceived),
    //   center: true
    // },
    {
      name: t("Terminal"),
      selector: i => i.CreatedByName,
      center: true
    },
    {
      name: t("Estatus"),
      selector: row => t(formatStatus(row?.InvoiceStatus)),
      center: true
    },
    {
      name: t("Acciones"),
      cell: row => (
        <>
          <Actions row={row} />
          <ReactTooltip />
        </>
      ),
      minWidth: "15%",
      button: true,
      center: true
    }
  ];

  const TableStyles = {
    rows: {
      style: {
        fontSize: 14,
        color: "#777777"
      }
    }
  };

  const CustomInput = ({ onClick, label, value, className }) => (
    <Button onClick={onClick} className={className}>
      <FaRegCalendarAlt className="dateIcon" /> {value || label}
      <div className="arrow-down" />
    </Button>
  );

  const formatStatus = status => {
    return STATUS.find(e => e.value === status)?.label || "";
  };

  const onCancel = transaction => {
    setSelectedTransaction(transaction.InvoiceId);
    setShowConfirm(true);
  };

  const onTrackTransaction = transaction => {
    dispatch(navigateTrackTransaction(transaction));
    history.push({ pathname: "/payments", state: { isTracking: true } });
  };

  const navigateDetails = movement => {
    movement.type = "pay";
    movement.currency = { code: movement.currency_to_paid };

    history.push({
      pathname: "/details",
      state: { movement }
    });
  };

  const cancelTransaction = async () => {
    setIsLoading(true);

    try {
      await axios.post(`${window.config.serverUri}CancelInvoice`, {
        InvoiceId: selectedTransaction
      });

      getTransactions(page, pageSize);
    } catch (error) {
      console.log(error.response);
    } finally {
      setSelectedTransaction(null);
      setShowConfirm(false);
      setIsLoading(false);
    }
  };

  const DateCol = ({ date }) => {
    return (
      <div className="column">
        <span>{moment(date).format(t("DD/MM/YYYY"))}</span>
        <span>{moment(date).format("hh:mm A")}</span>
      </div>
    );
  };

  const onPrint = transaction => {
    const content = document.getElementById(
      "transaction_to_print_" + transaction.InvoiceId
    );
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.head.innerHTML =
      pri.document.head.innerHTML +
      '<style type="text/css"> @media print { @page { margin: 0; } } </style>';
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const Actions = ({ row }) => {
    return (
      <Row className="flex-center">
        <Button
          className="action"
          modifier="quiet"
          onClick={() => navigateDetails(row)}
          data-tip="Más Información">
          <CgInfo />
        </Button>
        {row?.InvoiceStatus === "Pending" && (
          <>
            <Button
              className="action"
              modifier="quiet"
              onClick={() => onTrackTransaction(row)}
              data-tip="Volver a Transacción">
              <FiLogOut className="rotate" />
            </Button>
            <Button
              className="action"
              modifier="quiet"
              data-tip="Cancelar Transacción"
              onClick={() => onCancel(row)}>
              <CgCloseO />
            </Button>
            <Button
              className="action"
              modifier="quiet"
              data-tip="Imprimir"
              onClick={() => onPrint(row)}>
              <CgPrinter />
            </Button>
            <Printer transaction={row} />
          </>
        )}
      </Row>
    );
  };

  const itemToString = item => (item ? item.Username : "");
  const allUsers = (terminals || []).map(item => item.Username);

  const TablePaginationActions = ({ page, onChangePage }) => {
    const handleFirstPageButtonClick = () => {
      onChangePage(PAGE_BASE);
    };
    const handleBackButtonClick = () => {
      onChangePage(page - 1);
    };

    const handleNextButtonClick = () => {
      onChangePage(page + 1);
    };

    return (
      <>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          aria-label="next page"
          disabled={disableGotoNext}>
          <KeyboardArrowRight />
        </IconButton>
      </>
    );
  };

  const CustomMaterialPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
    itemsPerRow
  }) => {
    const ONE_BASED_PAGE = currentPage + 1;
    const size = ONE_BASED_PAGE * rowsPerPage - (rowsPerPage - itemsPerRow);
    return (
      <TablePagination
        component="nav"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={"Filas por página"}
        labelDisplayedRows={() =>
          `${size} Transacciones: página ${ONE_BASED_PAGE}`
        }
        rowsPerPageOptions={[10, 20, 40]}
        page={currentPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={({ target }) => {
          onChangeRowsPerPage(Number(target.value));
        }}
        ActionsComponent={TablePaginationActions}
      />
    );
  };

  return (
    <div className="content transactions">
      <iframe
        id="ifmcontentstoprint"
        title="Imprimir"
        style={{ display: "none", margin: 0 }}
      />
      <div className="content-title">
        <div>{t("Transacciones")}</div>
      </div>
      <div className="movements">
        <div>
          {userIsMerchant ? (
            <div
              className="button-blue select mr-07"
              style={{ color: "white" }}>
              <Downshift
                itemToString={itemToString}
                style={{ position: "absolute", zIndex: 9 }}>
                {({
                  getInputProps,
                  isOpen,
                  clearSelection,
                  getMenuProps,
                  getItemProps,
                  getToggleButtonProps,
                  highlightedIndex,
                  selectItem,
                  inputValue
                }) => (
                  <div style={{ marginTop: "5px" }}>
                    <input
                      value={filter}
                      style={{
                        color: "white",
                        backgroundColor: "#283090",
                        border: "none",
                        resize: "none",
                        outline: "none",
                        fontSize: "17px",
                        fontWeight: "600",
                        fontFamily:
                          "-apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif",
                        width: "70%"
                      }}
                      {...getInputProps({
                        onChange: e => {
                          resetPage();
                          setFilter(inputValue);
                          allUsers.forEach(user => {
                            if (user !== e.target.value) {
                              setSelectedTerminal(0);
                            }
                          });
                        },
                        onBlur: () => {
                          setFilter("");
                          allUsers.forEach(user => {
                            if (user !== inputValue) {
                              clearSelection();
                            }
                          });
                        }
                      })}
                      placeholder={t("Terminal")}
                    />
                    <button
                      {...getToggleButtonProps()}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#283090",
                        border: "none",
                        resize: "none",
                        outline: "none",
                        color: "white",
                        fontSize: "20px",
                        paddingTop: "5px"
                      }}>
                      {isOpen ? (
                        <IoMdArrowDropup size={17} />
                      ) : (
                        <IoMdArrowDropdown size={17} />
                      )}
                    </button>
                    <ul
                      {...getMenuProps({
                        style: {
                          border: isOpen && "1px solid black",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          color: "black",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "7px",
                          zIndex: 9,
                          position: "relative",
                          paddingInlineStart: "0px"
                        }
                      })}>
                      {isOpen &&
                        terminals.map((item, index) => (
                          <div
                            {...getItemProps({
                              item,
                              key: item.ApexUserId,
                              value: item.Username,
                              style: {
                                backgroundColor:
                                  index === highlightedIndex
                                    ? "#d3d3d3"
                                    : "white",
                                width: "100%",
                                cursor: "pointer"
                              }
                            })}
                            onClick={() => {
                              resetPage();
                              selectItem(item);
                              setFilter(item.Username);
                              setSelectedTerminal(parseInt(item.ApexUserId));
                            }}>
                            {item.Username}
                          </div>
                        ))}
                    </ul>
                  </div>
                )}
              </Downshift>
            </div>
          ) : null}
          <div className="button-blue select">
            <Select
              style={{ fontSize: "15px" }}
              value={selectedStatus}
              onChange={e => {
                resetPage();
                setSelectedStatus(e.target.value);
              }}>
              {[
                { value: 0, label: "Estatus" },
                { value: 1, label: "Iniciando" },
                { value: 2, label: "Aprobado" },
                { value: 3, label: "Rechazado" },
                { value: 4, label: "Cancelado" },
                { value: 5, label: "Ajustando" }
              ].map(option => (
                <option key={option.value} value={option.value}>
                  {t(option.label)}
                </option>
              ))}
            </Select>
          </div>
          <Datepicker
            locale={t("es")}
            selected={startDate}
            onChange={date => {
              resetPage();
              setStartDate(date);
            }}
            maxDate={endDate || moment.now()}
            value={startDate}
            dateFormat="dd/MM/yyyy"
            className="middle-picker"
            showTimeSelect
            customInput={
              <CustomInput label={t("Desde")} className="button-blue" />
            }
          />
          <Datepicker
            locale={t("es")}
            selected={endDate}
            onChange={date => {
              resetPage();
              setEndDate(date);
            }}
            value={endDate}
            minDate={startDate}
            maxDate={moment.now()}
            dateFormat="dd/MM/yyyy"
            timeClassName={time => checkEndDate(time, startDate)}
            showTimeSelect
            customInput={
              <CustomInput label={t("Hasta")} className="button-blue" />
            }
          />
          <div
            className="button-blue"
            style={{
              margin: "0 10px",
              marginTop: "15px",
              width: "170px !important",
              display: "inline-block",
              verticalAlign: "top",
              paddingTop: "0"
            }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontFamily:
                  "-apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif"
              }}
              onClick={() => getTransactions(page, pageSize)}>
              {!isLoading ? (
                <FaUndoAlt
                  className="type-icon"
                  style={{ marginRight: "1rem" }}
                />
              ) : (
                <FaSpinner
                  size={25}
                  style={{ marginRight: "1rem" }}
                  className={`icon-spin loader-blue`}
                />
              )}
              <span>{t("Actualizar")}</span>
            </div>
          </div>
        </div>
        <Row className="table-section">
          <Row className="flex-center actions-row">
            <div className="report-btn-container">
              <div className="flex-container">
                <img
                  className="reportImg"
                  alt="excelIcon"
                  src={excelIcon}
                  width={16}
                  height={16}
                />
                <Button
                  onClick={() => {
                    setShowExport(true);
                  }}
                  className="report-btn pointer">
                  {t("Generar Reporte")}
                </Button>
              </div>
            </div>
            <div className="report-btn-container">
              <div className="flex-container">
                <img
                  className="reportImg"
                  alt="excelIcon"
                  src={taxBtn}
                  width={16}
                  height={16}
                  style={{ opacity: "0.3" }}
                />
                <Button className="report-btn pointer" disabled>
                  {t("Pagar impuestos")}
                </Button>
              </div>
            </div>
          </Row>
        </Row>
        <div className="table-container">
          <DataTable
            customStyles={TableStyles}
            progressPending={isLoading}
            columns={columns}
            style={{
              marginTop: "10px",
              borderRadius: "20px",
              position: "absolute"
            }}
            data={transactions}
            paginationServer
            paginationTotalRows={pageSize * page + 0}
            paginationIconLastPage={() => {}}
            onChangePage={setPage}
            noDataComponent={t("No hay transacciones")}
            onChangeRowsPerPage={setPageSize}
          />
          {!isLoading && (
            <CustomMaterialPagination
              currentPage={page}
              onChangePage={setPage}
              onChangeRowsPerPage={setPageSize}
              rowCount={pageSize}
              rowsPerPage={pageSize}
              itemsPerRow={transactions.length}
            />
          )}
        </div>
      </div>
      <Dialog
        isOpen={showConfirm}
        setIsOpen={value => setShowConfirm(value)}
        loading={isLoading}
        onSubmit={() => cancelTransaction()}
        content={t("¿Deseas cancelar la transacción?")}
      />
      <Export
        isOpen={showExport}
        setIsOpen={value => setShowExport(value)}
        loading={isLoading}
        onSubmit={() => setShowExport(false)}
        terminals={terminals}
        userIsMerchant={userIsMerchant}
        merchant={merchant}
      />
    </div>
  );
};

export default Transactions;
