import axios from "../../utils/Axios";
import {
  CANCEL_PAYMENT,
  CONFIRM_PAYMENT,
  CREATE_PAYMENT,
  GET_CURRENCIES,
  RESET_PAYMENT,
  TRACK_PAYMENT,
} from "./processPayment.types";
import { logout } from "../../redux/Auth/auth.actions";
import { NotificationManager } from 'react-notifications';

export const getUsdNotionalRate = async (parameters = { Symbol: "BTC" }) => {
  const { data } = await axios.post(`${window.config.serverUri}GetUSDNotionalRate`, parameters);

  return data;
};

const manageError = (error, dispatch) => {
  if (error.status === 404) {
    dispatch(logout())
  } else {
    NotificationManager.error("Ocurrió un error al procesar los datos");
  }
};

const getMerchant = async (userId, callback, isPOS = false) => {
  try {
    let merchants;
    if (isPOS) {

      const { data } = await axios.get(`${window.config.merchantServerUri}GetMerchant?MerchantId=${userId}`);
      merchants = [data];
    } else {
      const { data } = await axios.get(`${window.config.merchantServerUri}GetMerchants?UserId=${userId}`);
      merchants = data;
    }

    if (merchants && merchants.length) {
      return callback(merchants[0]);
    }
    
    return callback(false);
  } catch (e) {
    console.log(e);
  }
};

export const getCurrencies = (data, setLoading) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${window.config.serverUri}GetProducts`, {
      OMSId: 1
    });
    dispatch({
      type: GET_CURRENCIES,
      payload: data,
    });
    setLoading(false)
    return data;
  } catch (error) {
    setLoading(false);
    console.log(error);
    manageError(error, dispatch);
  }
};
export const SATS_PER_BTC = 100000000;

export const createPayment = (payload, setLoading) => async (dispatch, getState) => {
  try {
    let MerchantPOSUserId = getState().auth?.user?.MerchantPOSUserId;
    const isPOS = MerchantPOSUserId && MerchantPOSUserId > 0;

    let merchantId = payload.UserId;
    if (isPOS) {
      merchantId = getState().auth?.user?.MerchantId
    } else {
      await getMerchant(merchantId, ({ MerchantId }) => merchantId = MerchantId, false);
    }

    const ProductId = payload.ProductId;

    const { data } = await axios.post(`${window.config.serverUri}CreateInvoice`, {
      MerchantId: merchantId,
      ProductId,
      ProductNetwork: payload.lightning ? "lightning" : "",
      NotionalAmount: parseFloat(payload.NotionalAmount),
      Amount: typeof payload.Amount === 'string' ? parseFloat(payload.Amount) : parseFloat(payload.Amount.toFixed(8)),
    })
    .catch(e => {
      if (e?.response?.status === 404) {
        dispatch(logout());
      }
    });

    if (data.result === false) {
      NotificationManager.error("Ocurrió un error al procesar los datos");
    } else {
      dispatch({
        type: CREATE_PAYMENT,
        payload: {
          ...payload,
          ...data,
          InvoiceId: data.InvoiceId
        }
      });
    }
    setLoading(false);
    return {}
  } catch (error) {
    setLoading(false);
    console.log(error);
    manageError(error, dispatch);
  }
};

export const cancelPayment = (setLoading, setShowConfirm) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_PAYMENT,
    });
    setLoading(false)
    setShowConfirm(false);
  } catch (error) {
    console.log(error);
    manageError(error, dispatch);
  }
};

export const trackPayment = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${window.config.serverUri}GetInvoice?InvoiceId=${id}/`);
    dispatch({
      type: TRACK_PAYMENT,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    console.log(error.response);
    manageError(error, dispatch);
  }
};

export const resetPayment = () => async (dispatch) => {
  console.log("Reset");
  try {
    dispatch({
      type: RESET_PAYMENT,
    });
  } catch (error) {
    console.log(error.response);
    manageError(error, dispatch);
  }
};

export const confirmPayment = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIRM_PAYMENT,
    });
  } catch (error) {
    console.log(error.response);
    manageError(error, dispatch);
  }
};

export const navigateTrackTransaction = (transaction) => async (dispatch) => {
  try {
    dispatch({
      type: TRACK_PAYMENT,
      payload: transaction,
    });
  } catch (error) {
    console.log(error.response);
    manageError(error, dispatch);
  }
};