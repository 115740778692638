import { Button, Modal } from "react-onsenui";
import { useTranslation } from 'react-i18next'
import Loader from "./Loader";

const Dialog = ({ isOpen, loading, onSubmit, setIsOpen, content }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen}>
      <div className="card dialog-center">
        <div className="card__content flex-center flex-col">
          <h2 className="card__title">{content}</h2>
          <Button
            className="button-blue inline-button text-align-center mt-2"
            disabled={loading}
            onClick={() => onSubmit()}
          >
            {loading ? <Loader /> : "Ok"}
          </Button>
          <Button
            className="button-blue inline-button text-align-center mt-2"
            onClick={() => setIsOpen(false)}
          >
            {t('Cancelar')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;