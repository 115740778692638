/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Col, Row, Button, Switch } from "react-onsenui";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AddAmount = ({ amount, setAmount, setStep }) => {
  const { t } = useTranslation();
  const [currencyBTC, setCurrencyBTC] = useState(true);

  const handleState = newDigit => {
    if (newDigit === ".") {
      setAmount(prevState =>
        String(prevState).includes(".") ? prevState : prevState + newDigit
      );
    } else if (newDigit === "-") {
      setAmount(prevState =>
        String(prevState).length === 1 ? 0 : String(prevState).slice(0, -1)
      );
    } else {
      setAmount(prevState =>
        String(prevState) === "0" || prevState === ""
          ? newDigit + ""
          : prevState + newDigit
      );
    }
  };

  return (
    <>
      <div className="title">{t("Enviar dinero")}</div>
      <Col className="input-col input-amount" width="50%">
        <Row className="row-size">
          <div style={{ backgroundColor: "#E7B403" }} className="color-box" />
          <div className="name-option">{t("Patricia Portillo")}</div>
        </Row>
        <Row className="row-amount row-margin-top">
          <div className="circle-amount">
            <div className="amount-money">${amount}</div>
          </div>
        </Row>
        <Row className="row-amount row-margin-top">
          <Switch
            checked={currencyBTC}
            onChange={() => setCurrencyBTC(!currencyBTC)}
          />
        </Row>
      </Col>
      <Col className="btn-col" width="50%">
        <div className="numpad">
          {[...Array(3).keys()].map(i => {
            return (
              <Row>
                {[...Array(3).keys()].map(j => (
                  <div
                    className={`calculator-number 
                        ${
                          i === 0 && j === 0
                            ? "top-left"
                            : i === 0 && j === 2
                            ? "top-right"
                            : ""
                        }
                      `}
                    onClick={() => handleState(i * 3 + (j + 1))}
                  >
                    {i * 3 + (j + 1)}
                  </div>
                ))}
              </Row>
            );
          })}
          <Row>
            <div
              className="calculator-number bottom-left"
              onClick={() => handleState(".")}
            >
              .
            </div>
            <div className="calculator-number" onClick={() => handleState("0")}>
              0
            </div>
            <div
              className="calculator-number bottom-right"
              onClick={() => handleState("-")}
            >
              <FaArrowLeft />
            </div>
          </Row>
        </div>
        <Button
          className="button-blue inline-button pointer"
          onClick={() => setStep("confirm")}
        >
          {t("Confirmar")}
        </Button>
      </Col>
    </>
  );
};

export default AddAmount;
