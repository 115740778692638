import React, { useState, useEffect } from "react";
import { Button, Row } from "react-onsenui";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelPayment,
  createPayment,
  getCurrencies,
  resetPayment
} from "../../redux/ProcessPayment/processPayment.actions";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Loader from "../../components/Loader";
import Dialog from "../../components/Dialog";
import { useTranslation } from "react-i18next";
import {
  userIdSelector,
  accountIdSelector,
  userFullNameSelector,
  qrCodeNameSelector,
  userIsMerchantSelector
} from "../../redux/selectors";
import { CONVERT_OPTIONS } from "../../constants";
import axios from "../../utils/Axios";
import { logout, updateFiatConversion } from "../../redux/Auth/auth.actions";

const ProcessPayments = ({ history }) => {
  const { t } = useTranslation();
  const preamount = history.location.state?.preamount || 0;
  const { payment, step } = useSelector(state => state.paymentMethod);
  const {
    user: { ConvertCurrency = CONVERT_OPTIONS.none }
  } = useSelector(state => state.auth);
  const [initialConvertOption, setInitialConvertOption] = useState(
    ConvertCurrency
  );
  const [selectedMethod, setSelectedMethod] = useState(
    payment?.ProductId === 2 ? "BTC" : "USD"
  );

  const [amount, setAmount] = useState(parseFloat(payment?.Amount) || "0");
  const [convert, setConvert] = useState(ConvertCurrency);
  const [BTCAmount, setBTCAmount] = useState(
    parseFloat(payment?.Amount) || "0"
  );
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const UserId = useSelector(userIdSelector);
  const AccountId = useSelector(accountIdSelector);

  const nextStep = () => {
    if (step === 1) {
      setLoading(true);
      const payload = {
        amount: amount,
        base: "USD"
      };
      try {
        dispatch(getCurrencies(payload, setLoading));
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else if (step === 2) {
      setLoading(true);

      const payload = {
        UserId,
        AccountId,
        NotionalAmount: amount,
        Amount: selectedMethod === "BTC" ? BTCAmount : amount,
        ProductId: selectedMethod === "BTC" ? 2 : 1
      };
      try {
        dispatch(createPayment(payload, setLoading));
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const previousStep = () => {
    if (step === 3) setShowConfirm(true);
    else if (step === 2) dispatch(resetPayment());
  };

  const FullName = useSelector(userFullNameSelector);
  const Name = useSelector(qrCodeNameSelector);
  const {
    user: { AvatarImage }
  } = useSelector(state => state.auth);

  const userIsMerchant = useSelector(userIsMerchantSelector);

  const onCancelPayment = async () => {
    try {
      if (userIsMerchant) {
        const { data } = await axios.post(
          `${window.config.serverUri}SetUserProfile`,
          {
            UserId,
            AccountId,
            AvatarImage,
            Name,
            FullName,
            ConvertCurrency: initialConvertOption,
            UserProfileType: "MerchantId"
          }
        );
        if (data.result) {
          dispatch(updateFiatConversion(initialConvertOption));
        }
      }
      setLoading(true);
      setSelectedMethod("");

      dispatch(cancelPayment(setLoading, setShowConfirm));
      const { data } = await axios.post(
        `${window.config.serverUri}CancelInvoice`,
        {
          InvoiceId: payment?.InvoiceId
        }
      );
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch(logout());
      }
      setShowConfirm(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step === 1) {
      Number(amount) !== 0 ? setDisabled(false) : setDisabled(true);
    } else {
      step === 2 && !selectedMethod ? setDisabled(true) : setDisabled(false);
    }
  }, [amount, selectedMethod, step]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetPayment());
    };
  }, []);

  return (
    <div className="process-payments">
      <Dialog
        isOpen={showConfirm}
        setIsOpen={value => setShowConfirm(value)}
        loading={loading}
        onSubmit={() => onCancelPayment()}
        content={t("¿Deseas cancelar la transacción?")}
      />

      <div className="button-section">{t("Procesador de Pago")}</div>
      <div className="text-info">{t("Ingresa la información requerida")}</div>
      <Row className="row-steps">
        <div className={step === 1 ? "back-blue option-step" : "option-step"}>
          {" "}
          {t("Monto a facturar")}
        </div>
        <div className={step === 2 ? "back-blue option-step" : "option-step"}>
          {t("Método de pago")}
        </div>
        <div className={step === 3 ? "back-blue option-step" : "option-step"}>
          {t("Dirección de pago")}
        </div>
      </Row>
      {step === 1 ? (
        <Step1
          preamount={preamount}
          setAmountTotal={amount2 => setAmount(amount2)}
          convert={convert}
          setConvert={value => setConvert(value)}
        />
      ) : step === 2 ? (
        <Step2
          amount={amount}
          selectedMethod={selectedMethod}
          setSelectedMethod={data => setSelectedMethod(data)}
          setBTCAmount={data => setBTCAmount(data)}
        />
      ) : (
        <Step3 history={history} />
      )}
      <Row
        className={
          step === 1
            ? "row-btn row-end btn-step3"
            : `row-btn ${step === 3 ? "btn-step3" : ""}`
        }
      >
        {step !== 1 && (
          <Button
            className={step === 2 ? "btn-steps btn-gray" : "btn-steps"}
            onClick={() => previousStep()}
          >
            {t("Anterior")}
          </Button>
        )}
        {step === 1 || step === 2 ? (
          <Button
            className="btn-steps btn-end"
            onClick={() => nextStep()}
            id="btn-next"
            disabled={disabled || loading}
          >
            {loading ? <Loader /> : t("Siguiente")}
          </Button>
        ) : null}
      </Row>
    </div>
  );
};

export default ProcessPayments;
