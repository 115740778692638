import React, { useState, useEffect } from "react";
import { Row } from "react-onsenui";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Calculator from "../../styles/components/Calculator";
import { formatAmount } from "../../utils/formatNumber";

const Step1 = ({
  preamount,
  convert,
  setConvert = e => e,
  setAmountTotal = e => e
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(parseFloat(preamount));
  const { user } = useSelector(state => state.auth);

  const handleState = newDigit => {
    if (newDigit === ".") {
      setAmount(prevState =>
        prevState.includes(".") ? prevState : prevState + newDigit
      );
    } else if (newDigit === "-") {
      setAmount(prevState => prevState.slice(0, -1));
    } else {
      setAmount(prevState => {
        if (prevState === "0") {
          return newDigit;
        }
        if (prevState.includes(".")) {
          if (prevState.split(".")[1].length >= 2) {
            return prevState;
          }
        }
        if(prevState.toString().length >= 10){
          return prevState;
        }

        return prevState + newDigit;
      });
    }
  };

  useEffect(() => {
    if (!amount) {
      setAmount("0");
    }
  }, [amount]);

  useEffect(() => {
    setAmountTotal(amount);
  }, [amount, setAmountTotal]);

  return (
    <div className="payment-step">
      <div className="message">{t("Monto a facturar y moneda base")}</div>
      <div>
        <Row className="amount-send">
          <div className="amount-money" style={{ color: "black"}}>{formatAmount(amount)}</div>
          <div className="money-select">USD</div>
        </Row>
      </div>
      <Calculator handleState={handleState} />
      {false && user?.UserProfileType === "MerchantId" && (
        <Row className="switch-row">
          <div className="switch-text">{t("Convertir automaticamente")}</div>
          <label className="switch-box">
            <input
              type="checkbox"
              checked={convert}
              onChange={() => setConvert(!convert)}
            />
            <div className="slider round" />
            {/* {convert ? (
              <div className="switch-text-yes">Sí</div>
            ) : (
              <div className="switch-text-no">No</div>
            )} */}
          </label>
        </Row>
      )}
    </div>
  );
};

export default Step1;
