import axios from "axios";
import history from "../history";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("merchantSessionToken");

    if (token) {
      config.headers.aptoken = token;
    }
    config.headers["Content-type"] = "application/json";
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Refresh Token was expired
      if (
        err.response.status === 401 &&
        originalConfig.url === "/api/v1/auth/token/refresh/"
      ) {
        history.push("/login");
        return Promise.reject(err);
      }

      if (err.response.status === 404) {
        history.push("/login");
        return Promise.reject(err);
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
