import React, { useEffect, useState, useRef } from "react";
import { Button, Row } from "react-onsenui";
import { Link, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/Auth/auth.actions";
import { accountIdSelector } from "../../redux/selectors";
import axios from "../../utils/Axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import { getMovementTypeValue } from "../Movements/constants";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import { FaUndoAlt } from "react-icons/fa";
import DashboardExport from "./DashboardExport";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const popoverRef = useRef();

  const AccountId = useSelector(accountIdSelector);

  const [balances, setBalances] = useState({
    USD: {
      Amount: 0
    },
    BTC: {
      Amount: 0,
      NotionalRate: 1,
      NotionalValue: 0
    }
  });
  const [movements, setMovements] = useState([]);
  const [isExportOpen, setIsExportOpen] = useState(false);

  const getBalances = async () => {
    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountPositions`,
        {
          OMSId: 1,
          AccountId
        }
      );

      const transformedData = data.reduce((acc, el) => {
        acc[el.ProductSymbol] = el;
        return acc;
      }, {});

      setBalances(transformedData);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error("Ocurrió un error al cargar el balance");
      }
    }
  };

  const getMovements = async () => {
    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountTransactionDetailHistory`,
        {
          OMSId: 1,
          AccountId,
          Depth: 10
        }
      );
      setMovements(data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getMovements();
    getBalances();
  }, []);

  return (
    <div className="content dashboard">
      <div className="cards">
        <div className="calc-card">
          <div>
            <div>
              <div className="payProccesBtn">
                <Link to="/payments">
                  <Button modifier="quiet" className="transparent-btn pointer">
                    <Row className="payProccesBtnTxt">
                      {t("Procesar pagos")}
                    </Row>
                  </Button>
                </Link>
              </div>
            </div>
            <div className="container-usd-btc">
              <div className="usd-btc usd">
                <div className="usd-btc-title usd-title-bg">USD</div>
                <div className="usd-btc-amount">
                  {formatUSDNumber((balances["USD"]?.Amount - balances["USD"]?.Hold))}
                </div>
                <div className="usd-btc-result">
                  {formatNumber(
                    (((balances["USD"]?.Amount  - balances["USD"]?.Hold) / balances["BTC"]?.NotionalRate))
                  )}
                  {" BTC"}
                </div>
              </div>
              <div className="usd-btc btc">
                <div className="usd-btc-title btc-title-bg">BTC</div>
                <div className="usd-btc-amount">
                  {formatNumber((balances["BTC"]?.Amount - balances["BTC"]?.Hold))}
                </div>
                <div className="usd-btc-result">
                  {formatUSDNumber((balances["BTC"]?.NotionalValue - balances["BTC"]?.Hold))}
                  {" USD"}
                </div>
              </div>
            </div>
          </div>

          <div>
            <Link to="/payments" className="process-payments-button">
              <Button
                className="button-blue pointer"
                style={{ marginBottom: "20px" }}
              >
                {t("Procesar pagos")}
              </Button>
            </Link>
          </div>
        </div>

        <div className="transactions-card">
          <Row
            className="flex-center"
            style={{ flexWrap: 'nowrap', justifyContent: "center", padding: "0 2rem" }}
          >
            <Button
              disabled={movements.length === 0}
              onClick={() => setIsExportOpen(true)}
              className="action-btn pointer"
            >
              {t("Reporte de Transacciones")}
            </Button>
            <Button
              disabled
              className="action-btn pointer"
              onClick={() => {}}
            >
              {t("Pagar Impuestos")}
            </Button>
            <Button
              className="action-btn pointer"
              style={{
                width: "unset",
                display: "flex",
                alignItems: "center",
                margin: "unset",
                marginBottom: "1rem"
              }}
              onClick={getMovements}
              data-tip="Actualizar"
              id="refresh"
            >
              <FaUndoAlt className="type-icon" size={22} />
            </Button>
          </Row>
          <div className="flex-cardTitle">
            <div className="title">{t("Transacciones")}</div>
            <div className="view" onClick={() => history.push("/movements")}>
              {t("Ver todas")}
            </div>
          </div>
          {movements.map(i => {
            let amount = i.Amount;
            let product = i.ProductId;
            let endProduct;
            if (i.ConversionDetails.length) {
              let [
                startingProduct,
                endingProduct,
                endingAmount
              ] = i.ConversionDetails.split("|");
              endProduct = parseInt(endingProduct);
              amount = endingAmount;
              product = i.ProductId === 1 ? 2 : 1;
            }
            const date = moment(i.TimeStamp)
              .format("DD [de] MMMM")
              .split(" ");
            let formattedDate = `${date[0]} ${t(date[1])} ${t(date[2])}`;
            return (
              <div className="flex-cardRow" key={i.TransactionId}>
                <div
                  ref={popoverRef}
                  data-tip={i.TransactionId}
                  className={`square ${product === 1 ? "blue" : "orange"}`}
                >
                  {getMovementTypeValue(i)?.icon}
                </div>
                <div className="desc">
                  <div className="date">{formattedDate}</div>
                  {i.ConversionDetails.length ? (
                    <div className="date">{t("Convertiste")}</div>
                  ) : null}
                  <div className="type">
                    {t(`${getMovementTypeValue(i)?.label}`)}
                  </div>
                </div>
                <div className="amount">
                  <div>
                    {amount > 0
                      ? `+${
                          product === 1
                            ? `$${formatUSDNumber(amount)}`
                            : formatNumber(amount)
                        } `
                      : `-${product === 1 ? "$" : ""}${
                          product === 1
                            ? formatUSDNumber(Math.abs(amount))
                            : formatNumber(Math.abs(amount))
                        } `}
                    {product === 1 ? "USD" : "BTC"}
                  </div>
                  <div>
                    {i.ProductId === 2 && endProduct !== 1
                      ? `${amount > 0 ? "+" : "-"}${formatUSDNumber(
                          Math.abs(i.NotionalAmount)
                        )} USD`
                      : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <DashboardExport
        isOpen={isExportOpen}
        setIsOpen={value => setIsExportOpen(value)}
      />
    </div>
  );
};

export default Dashboard;
