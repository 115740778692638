import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { accountIdSelector } from "../../redux/selectors";
import { Button, Row } from "react-onsenui";
import DataTable from "react-data-table-component";
import { NotificationManager } from "react-notifications";
import excelIcon from "../../assets/images/excel-icon.png";
import { export2CSV } from "../../utils/export2csv";
import axios from "../../utils/Axios";
import moment from "moment";
import Loader from "../../components/Loader";
import { BANKS } from "../Settings/banks";
import { useTranslation } from "react-i18next";
import { formatUSDNumber } from "../../utils/formatNumber";
import translationWithdraw from "../../utils/translationWithdraw";
import "../../styles/views/Withdrawals/index.css";

const Withdrawals = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [withdrawals, setWithdrawals] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [balance, setBalance] = useState({ Amount: 0 });
  const [msj] = useState(t("Ocurrió un error al cargar el balance"));

  const columns = [
    {
      name: t("Fecha"),
      selector: row => {
        return <DateCol date={row.LastUpdateTimestamp} />;
      },
      center: true
    },
    {
      name: t("Banco"),
      selector: row => formatBank(row),
      center: true
    },
    {
      name: t("Monto"),
      selector: row => <AmountCol amount={row.Amount} />,
      center: true
    },
    {
      name: t("Estatus"),
      selector: row => <StatusCol status={formatStatus(row.Status)} />,
      center: true
    }
  ];

  const TableStyles = {
    rows: {
      style: {
        fontSize: 14,
        color: "#000000",
        "&:not(:last-of-type)": {
          borderBottomStyle: "none"
        }
      }
    },
    headCells: {
      style: {
        fontSize: "1rem",
        fontWeight: 600
      }
    },
    headRow: {
      style: {
        borderBottomStyle: "none"
      }
    },
    pagination: {
      style: {
        border: "none",
        borderRadius: "20px",
        color: "black",
        fontSize: "15px",
        justifyContent: "center"
      }
    }
  };

  const formatStatus = status => {
    if (status === "sending" || status === "initial") {
      return t("Aprobado");
    } else {
      return getStatus(status) || "";
    }
  };

  const formatBank = row => {
    let data = {};
    try {
      data = JSON.parse(row.TemplateForm);
    } catch (e) {}
    if (data) {
      let bankName = BANKS.find(i => i.code === data.BankCode)?.name;
      if (!bankName) {
        bankName = BANKS.find(i => i.code === data.Name)?.name;
      }
      return bankName;
    }
    return "";
  };

  const DateCol = ({ date }) => {
    return (
      <div className="column">
        <Row>{moment(date).format(t("DD/MM/YYYY"))}</Row>
        <Row className="time">{moment(date).format("h:mm A")}</Row>
      </div>
    );
  };

  const AmountCol = ({ amount }) => {
    return (
      <div className="column">
        <span className="wd-amount">$ {amount}</span>
      </div>
    );
  };

  const StatusCol = ({ status }) => {
    return (
      <div className="column">
        <span className="status">{t(status)}</span>
      </div>
    );
  };

  const getWithdrawals = async (StartIndex = 0) => {
    setIsLoading(true);

    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetWithdrawTickets`,
        {
          OMSId: 1,
          Limit: pageSize,
          StartIndex: StartIndex * pageSize,
          OperatorId: 1,
          AccountProviderId: window.config.BHAccountProviderId,
          ProductId: 1,
          AccountId
        }
      );

      const withdrawals = data.sort((a, b) => {
        return b.LastUpdateTimestampTick - a.LastUpdateTimestampTick;
      });
      setWithdrawals(withdrawals);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const AccountId = useSelector(accountIdSelector);

  const getBalance = async () => {
    setIsLoadingBalance(true);

    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountPositions`,
        {
          OMSId: 1,
          AccountId
        }
      );

      setBalance(data.find(i => i.ProductId === 1));
    } catch (error) {
      NotificationManager.error(msj);
    }
    setIsLoadingBalance(false);
  };

  useEffect(
    () => {
      getBalance();
      getWithdrawals();
    },
    [pageSize]
  );

  return (
    <>
      <div className="withdrawals table-section">
        <Row className="header-section justify-content-between">
          <div className="wd-title">{t("Últimos Retiros")}</div>
          <div className="column balance">
            <span className="time">{t("Disponibles")}</span>
            <span className="available-amount">
              {isLoadingBalance ? (
                <Loader />
              ) : (
                `$ ${formatUSDNumber(balance.Amount - balance.Hold)}`
              )}
            </span>
          </div>
        </Row>
        <div className="wd-table-container">
          <DataTable
            pagination
            paginationServer
            customStyles={TableStyles}
            progressPending={isLoading}
            columns={columns}
            data={withdrawals}
            noDataComponent="No hay retiros"
            onChangeRowsPerPage={setPageSize}
            paginationTotalRows={pageSize * page + 1}
            onChangePage={Page => {
              if (withdrawals.length >= pageSize || Page <= page) {
                getWithdrawals(Page - 1);
                setPage(Page);
              }
            }}
            paginationIconLastPage={() => {}}
            paginationComponentOptions={{
              noRowsPerPage: true
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          <div className="flex-container">
            <Button
              className="button-blue"
              onClick={() => history.push("/settings")}
              style={{ marginBottom: "15px" }}>
              {t("Anterior")}
            </Button>
            <div
              className="report-btn-container"
              style={{ height: "40px", marginTop: "15px", marginLeft: "10px" }}>
              <div className="flex-container">
                <img
                  className="reportImg"
                  alt="excelIcon"
                  src={excelIcon}
                  width={16}
                  height={16}
                />
                <Button
                  onClick={() => {
                    withdrawals.forEach(x => {
                      Object.keys(x).forEach(key => {
                        if (key in translationWithdraw) {
                          x[translationWithdraw[key]] = x[key];
                          delete x[key];
                        }
                      });
                    });
                    export2CSV(withdrawals, "Retiros");
                  }}
                  className="report-btn pointer">
                  {t("Generar Reporte")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getStatus = apexStatus => {
  switch (apexStatus) {
    case "Rejected":
    case "AdminCancelled":
    case "UserCancelled":
    case "Failed":
      return "Cancelado";

    case "FullyProcessed":
      return "Aprobado";

    case "New":
      return "Iniciando";

    default:
      return "En proceso";
  }
};

const STATUS = {
  New: "Iniciando",
  sending: "En proceso",
  approved: "Aprobado",
  cancelled: "Cancelado"
};

export default Withdrawals;
