import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Button, Modal, Col, Checkbox } from "react-onsenui";
import Datepicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import moment from "moment";
import Downshift from "downshift";
import Loader from "../../components/Loader";
import { matchSorter } from "match-sorter";
import { export2CSV } from "../../utils/export2csv";
import { invoiceStatusMapping } from "../../constants";
import axios from "../../utils/Axios";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/Auth/auth.actions";
import translationsTranscations from "../../utils/translationsTranscations";

const Export = ({
  isOpen,
  loading,
  onSubmit,
  setIsOpen,
  content,
  userIsMerchant,
  terminals,
  merchant
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const itemToString = item => (item ? item.Username : "");
  const allUsers = terminals.map(item => item.Username);

  const CustomInput = ({ onClick, label, value, className }) => (
    <Button
      onClick={onClick}
      className={className}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
      <FaRegCalendarAlt className="dateIcon" style={{ marginTop: "0px" }} />{" "}
      {value || label}
      <div className="arrow-down" />
    </Button>
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedTerminal, setSelectedTerminal] = useState(0);
  const [filter, setFilter] = useState("");
  const [isExcel, setIsExcel] = useState(false);

  const AccountName = useSelector(state => {
    if (state.auth?.user?.UserProfileType === "MerchantId") {
      return state?.auth?.user?.AccountName;
    }
    return state?.auth?.user?.MerchantName;
  });

  const NIT = useSelector(state => {
    if (state.auth?.user?.UserProfileType === "MerchantId") {
      return state?.auth?.user?.Identifier;
    }
    return state?.auth?.user?.MerchantIdentifier;
  });

  const downloadFile = async () => {
    const payload = {
      startDate:
        (startDate && moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss")) ||
        undefined,
      endDate:
        (endDate && moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")) ||
        undefined,
      InvoiceStatus: selectedStatus,
      CreatedBy: selectedTerminal
    };

    const { data } = await axios
      .post(`${window.config.serverUri}GetInvoices`, {
        OMSId: 1,
        MerchantId: merchant,
        Limit: 2147483647,
        ...payload
      })
      .catch(e => {
        if (e.response.status === 404) {
          dispatch(logout());
        }
      });

    if (data.length) {
      let totals = {
        InvoiceId: t("Total")
      };
      const transformedData = data.reduce((acc, i) => {
        const { ProductId, ...rest } = i;
        if (!totals.Amount) {
          totals.Amount = i.Amount;
        } else {
          totals.Amount += i.Amount;
        }

        if (!totals.NotionalAmount) {
          totals.NotionalAmount = i.NotionalAmount;
        } else {
          totals.NotionalAmount += i.NotionalAmount;
        }

        if (!totals.AmountReceived) {
          totals.AmountReceived = i.AmountReceived;
        } else {
          totals.AmountReceived += i.AmountReceived;
        }

        if (!totals.NotionalAmountReceived) {
          totals.NotionalAmountReceived = i.NotionalAmountReceived;
        } else {
          totals.NotionalAmountReceived += i.NotionalAmountReceived;
        }

        acc.push({
          ...rest,
          CreatedDT: moment(i.CreatedDT).format("DD/MM/YY hh:mm A"),
          UpdatedDT: moment(i.UpdatedDT).format("DD/MM/YY hh:mm A"),
          Product: ProductId === 1 ? "USD" : "BTC",
          InvoiceStatus: invoiceStatusMapping[(i?.InvoiceStatus)]
        });

        return acc;
      }, []);

      transformedData.push(totals);
      transformedData.forEach(x => {
        Object.keys(x).forEach(key => {
          if (key in translationsTranscations) {
            x[translationsTranscations[key]] = x[key];
            delete x[key];
          }
        });
      });

      return export2CSV(
        transformedData,
        t("Transacciones"),
        csvString => {
          const header =
            "CHIVO WALLET | RESUMEN DE OPERACIONES,,,,,,,,,,,,,,,,,,,,,,\r\n";
          const header2 =
            "CONTRIBUYENTE:," + AccountName + ",NIT:," + NIT + ",";

          let extraCommas = ",,,,,,,,,,,,,,,,,,\r\n";
          if (startDate) {
            if (!endDate) {
              extraCommas =
                "PERIODO:," +
                startDate.toLocaleString("es") +
                " a " +
                new Date().toLocaleString("es") +
                ",,,,,,,,,,,,,,,,,\r\n";
            } else {
              extraCommas =
                "PERIODO:," +
                startDate.toLocaleString("es") +
                " a " +
                endDate.toLocaleString("es") +
                ",,,,,,,,,,,,,,,,,\r\n";
            }
          }

          return header + header2 + extraCommas + csvString;
        },
        isExcel
      )
        .then(() => {
          NotificationManager.success(t("Éxito"));
        })
        .catch(() => {
          NotificationManager.error(
            t("Ocurrió un error al cargar las transacciones")
          );
        });
    } else {
      NotificationManager.error(t("No hay transacciones"));
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="card dialog-center">
        <div className="card__content flex-center flex-col">
          <h2 className="card__title">Generar Reporte</h2>
          <h4 className="card__title">
            Selecciona el rango de fechas que deseas imprimir en tu reporte
          </h4>
          <div className="movements">
            <div
              className="content transactions options-container"
              style={{ minHeight: "0px" }}>
              {userIsMerchant && (
                <div
                  className="button-blue select mr-07 export-option"
                  style={{ color: "white" }}>
                  <Downshift
                    inputValue={filter}
                    itemToString={itemToString}
                    style={{ position: "absolute", zIndex: 9 }}>
                    {({
                      getInputProps,
                      isOpen,
                      clearSelection,
                      getMenuProps,
                      getItemProps,
                      getToggleButtonProps,
                      highlightedIndex,
                      selectItem,
                      inputValue
                    }) => (
                      <div style={{ marginTop: "5px" }}>
                        <input
                          value={filter}
                          style={{
                            color: "white",
                            backgroundColor: "#283090",
                            border: "none",
                            resize: "none",
                            outline: "none",
                            fontSize: "17px",
                            width: "60%",
                            fontFamily:
                              "-apple-system,'Helvetica Neue',Helvetica,Arial,'Lucida Grande',sans-serif",
                            fontWeight: 600,
                            paddingLeft: "20px"
                          }}
                          {...getInputProps({
                            onChange: e => {
                              setFilter(e.target.value);
                              allUsers.forEach(user => {
                                if (user !== e.target.value) {
                                  setSelectedTerminal(0);
                                }
                              });
                            },
                            onBlur: () => {
                              setFilter("");
                              allUsers.forEach(user => {
                                if (user !== inputValue) {
                                  clearSelection();
                                }
                              });
                            }
                          })}
                          placeholder={t("Terminal")}
                        />
                        <button
                          {...getToggleButtonProps()}
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "#283090",
                            border: "none",
                            resize: "none",
                            outline: "none",
                            color: "white",
                            fontSize: "20px",
                            paddingTop: "5px"
                          }}>
                          {isOpen ? (
                            <IoMdArrowDropup size={17} />
                          ) : (
                            <IoMdArrowDropdown size={17} />
                          )}
                        </button>
                        <ul
                          {...getMenuProps({
                            style: {
                              border: isOpen && "1px solid black",
                              maxHeight: "150px",
                              overflowY: "scroll",
                              color: "black",
                              backgroundColor: "#FFFFFF",
                              borderRadius: "7px",
                              zIndex: 9,
                              position: "relative",
                              paddingInlineStart: "0px"
                            }
                          })}>
                          {isOpen &&
                            terminals.map((item, index) => (
                              <div
                                {...getItemProps({
                                  item,
                                  key: item.ApexUserId,
                                  style: {
                                    backgroundColor:
                                      index === highlightedIndex
                                        ? "#d3d3d3"
                                        : "white",
                                    width: "100%",
                                    cursor: "pointer"
                                  }
                                })}
                                onClick={() => {
                                  selectItem(item);
                                  setFilter(item.Username);
                                  setSelectedTerminal(
                                    parseInt(item.ApexUserId)
                                  );
                                }}>
                                {item.Username}
                              </div>
                            ))}
                        </ul>
                      </div>
                    )}
                  </Downshift>
                </div>
              )}
              <div className="button-blue select export-option">
                <Select
                  value={selectedStatus}
                  style={{ color: "white", fontSize: "15px" }}
                  onChange={e => {
                    setSelectedStatus(e.target.value);
                  }}>
                  {[
                    { value: 0, label: "Estatus" },
                    { value: 1, label: "Iniciando" },
                    { value: 2, label: "Aprobado" },
                    { value: 3, label: "Rechazado" },
                    { value: 4, label: "Cancelado" },
                    { value: 5, label: "Ajustando" }
                  ].map(option => (
                    <option key={option.value} value={option.value}>
                      {t(option.label)}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Datepicker
                  locale={t("es")}
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  maxDate={endDate || moment.now()}
                  value={startDate}
                  dateFormat="dd/MM/yyyy"
                  className="middle-picker"
                  showTimeSelect
                  customInput={
                    <CustomInput
                      label={t("Desde")}
                      className="button-blue export-option"
                    />
                  }
                />
              </div>
              <div>
                <Datepicker
                  locale={t("es")}
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  value={endDate}
                  minDate={startDate}
                  maxDate={moment.now()}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  customInput={
                    <CustomInput
                      label={t("Hasta")}
                      className="button-blue export-option"
                    />
                  }
                />
              </div>
            </div>
          </div>
          {/* <Col width="18%" style={{ margin: '10px 0px 0px'}}>
              <div className="form-col-input" style={{
                background: "#283090",
                color: "white",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                fontWeight: '600',
                padding: '0px',
                height: '40px',
                fontSize: '15px',
              }}>
                Para Excel?
                <Checkbox 
                  modifier="noborder"
                  className="status-icon white-check"
                  style={{ margin: '0', color: 'white', border: '2px solid white', fontWeight: '600', height: '18px', width: '18px' }}
                  onChange={() => setIsExcel(!isExcel)}
                />
              </div>
            </Col> */}
          <Button
            style={{ margin: "0px", cursor: "pointer", marginTop: "10px" }}
            className="button-blue inline-button text-align-center"
            disabled={loading}
            onClick={() => {
              downloadFile();
              onSubmit();
            }}>
            {loading ? <Loader /> : "Ok"}
          </Button>
          <Button
            className="button-blue inline-button text-align-center"
            onClick={() => setIsOpen(false)}
            style={{ cursor: "pointer" }}>
            {t("Cancelar")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Export;
