/* eslint-disable react/style-prop-object */
import React from "react";
import { Col, Row, Button, Input } from "react-onsenui";
import { useTranslation } from "react-i18next";

const ToFriends = ({ setStep }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="title">{t("Enviar dinero")}</div>
      <Row className="flex-center">
        <Col className="input-col" width="70%">
          <Button
            className="button-blue pointer qr-button"
            style={{
              marginBottom: "20px",
              paddingTop: 0,
              width: "225px"
            }}
            onClick={() => setStep("scanQR")}
          >
            {t("Escanear código QR")}
          </Button>
          <Row className="row-item">
            <div className="title-item">{t("Para:")}</div>
            <div className="value-item">
              <Input value="5454748494" />
            </div>
          </Row>
          <Row className="row-item">
            <div className="title-item">{t("Nota:")}</div>
            <div className="value-item">
              <Input value="Compra" />
            </div>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-end w-90">
        <Button
          className="button-blue inline-button pointer center-inline-button"
          onClick={() => setStep("addAmount")}
        >
          {t("Pagar")}
        </Button>
      </Row>
    </>
  );
};

export default ToFriends;
