const translationWithdraw = {
    "AssetManagerId": "No. administrador activos",
    "AccountProviderId": "No. proveedor cuenta",
    "AccountId": "No. cuenta",
    "AccountName": "Nombre cuenta",
    "AssetId": "No. activo",
    "AssetName": "Nombre activo",
    "Amount": "Monto",
    "NotionalValue": "Valor nominal",
    "NotionalProductId": "Producto nominal",
    "TemplateForm": "Formulario plantilla",
    "TemplateFormType": "Tipo formulario plantilla",
    "OMSId": "No. OMS",
    "RequestCode": "Código solicitud",
    "RequestIP": "IP solicitud",
    "RequestUserId": "No. usuario solicitud",
    "RequestUserName": "Nombre usuario solicitud",
    "OperatorId": "No. operador",
    "Status": "Estatus",
    "FeeAmt": "Monto comisión",
    "UpdatedByUser": "Actualizado por usuario",
    "UpdatedByUserName": "Nombre actualizador",
    "TicketNumber": "Número ticket",
    "WithdrawTransactionDetails": "Detalles transacción retiro",
    "RejectReason": "Razón rechazo",
    "CreatedTimestamp": "Marca tiempo creación",
    "LastUpdateTimestamp": "Marca tiempo actualización",
    "CreatedTimestampTick": "Marca tiempo creación",
    "LastUpdateTimestampTick": "Marca tiempo actualización",
    "Comments": "Comentarios",
    "Attachments": "Adjuntos",
    "AuditLog": "Registro auditoría",
}
export default translationWithdraw;