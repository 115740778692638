import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "react-onsenui";
import { CgPrinter } from "react-icons/cg";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import Printer from "../../components/Printer";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import { getUsdNotionalRate } from "../../redux/ProcessPayment/processPayment.actions";
import { getMovementTypeValue, typeStyle } from "../Movements/constants";
import axios from "../../utils/Axios";
import { useTranslation } from "react-i18next";
import { logout } from "../../redux/Auth/auth.actions";

const MovementDetails = ({ history }) => {
  const { t } = useTranslation();
  const { movement } = history.location.state;
  const [notionalRate, setNotionalRate] = useState(0);
  const [selectedMovement, setSelectedMovement] = useState(movement || false);
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedMovement(movement);
    async function getRate() {
      const data = await getUsdNotionalRate();
      setNotionalRate(data);
    }
    getRate();
  }, []);

  const onPrint = () => {
    const content = document.getElementById(
      "transaction_to_print_" + movement.InvoiceId
    );
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.head.innerHTML =
      pri.document.head.innerHTML +
      '<style type="text/css"> @media print { @page { margin: 0; } } </style>';
    pri.document.close();
    pri.focus();
    pri.print();
  };

  useEffect(
    () => {
      let timerId;
      if (selectedMovement?.InvoiceStatus === "Pending") {
        timerId = setInterval(async () => {
          try {
            const { data } = await axios.post(
              `${window.config.serverUri}GetInvoice`,
              {
                InvoiceId: selectedMovement.InvoiceId
              }
            );

            if (data?.InvoiceStatus !== "Pending" && data?.result !== false) {
              setSelectedMovement(data);
            }
          } catch (e) {
            console.log(e);
            if (e.response.status === 404) {
              dispatch(logout());
            }
          }
        }, 3000);
      }

      return () => clearInterval(timerId);
    },
    [selectedMovement.InvoiceId]
  );

  return (
    <div className="content details column">
      <iframe
        id="ifmcontentstoprint"
        title="Imprimir"
        style={{ display: "none", margin: 0 }}
      />
      <Row>
        <Button
          modifier="quiet"
          className="transparent-btn pointer"
          onClick={() => history.goBack()}>
          <Row className="align-items-center">
            <IoIosArrowBack /> {t("Regresar")}
          </Row>
        </Button>
      </Row>
      <Row className="flex-center">
        <div className="box column">
          <h3>{t("Monto de la Factura")}</h3>
          <h5 className="amount mb-0">
            {formatUSDNumber(selectedMovement.NotionalAmount)} USD
          </h5>
          <p className="equivalent">
            {selectedMovement.ProductId === 1
              ? formatNumber(selectedMovement.Amount / notionalRate)
              : formatNumber(selectedMovement.Amount)}
          </p>
        </div>
      </Row>
      <h3 className="type">{t("Pago Recibido")}</h3>
      {getMovementTypeValue({ Description: "Sale" })?.icon ? (
        <div
          className={`square ${movement.ProductId === 2 ? "orange" : "blue"}`}>
          {getMovementTypeValue({ Description: "Sale" })?.icon}
        </div>
      ) : (
        <div className="square grey">
          <div className="star" />
        </div>
      )}
      <Col className="w-70">
        <Row>
          <Col className="box flex-center">
            <span className="label">{t("Recibido por:")}</span>
            <h4 className="value">
              {t("Account Id:")} {selectedMovement.AccountId}
            </h4>
          </Col>
          {/* <Col className="box flex-center">
          <span className="label">Telefono:</span>
          <h4 className="value">12345678</h4>
        </Col> */}
        </Row>
        <Row>
          <Col className="box section-left flex-center">
            <span className="label">
              {t("Fecha")}
              {":"}
            </span>
            <h4 className="value">
              {moment(selectedMovement.CreatedDT).format("DD-MM-YYYY")}
            </h4>
          </Col>
          <Col className="box section-right">
            <span className="label">
              {t("Estatus")}
              {":"}
            </span>
            <h4 className="value">
              <Row
                className="status-mark flex-center"
                style={{
                  color: typeStyle[selectedMovement.InvoiceStatus]?.color
                }}>
                {typeStyle[selectedMovement.InvoiceStatus]?.icon}
                {typeStyle[selectedMovement.InvoiceStatus]?.label}
              </Row>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col className="box section-left flex-center">
            <span className="label">{t("Comisión")}:</span>
            <h4 className="value">0.00</h4>
          </Col>
          <Col className="box section-right flex-center">
            <span className="label">{t("Total recibido (USD):")}</span>
            <h4 className="value">
              {formatUSDNumber(selectedMovement.NotionalAmountReceived)}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col className="box section-left flex-center">
            <span className="label">{t("Numero de Factura")}:</span>
            <h4 className="value">{selectedMovement.InvoiceId}</h4>
          </Col>
          {selectedMovement.ProductId === 2 ? (
            <Col className="box section-right flex-center">
              <span className="label">{t("Total recibido (BTC):")}</span>
              <h4 className="value">
                {formatNumber(selectedMovement.AmountReceived)}
              </h4>
            </Col>
          ) : (
            <Col
              className="box section-right flex-center"
              style={{ visibility: "hidden" }}
            />
          )}
        </Row>
        {/* {selectedMovement.InvoiceStatus === "Pending" && ( */}
        <Row className="justify-content-end mt-10px">
          <Button className="action" modifier="quiet" onClick={() => onPrint()}>
            <CgPrinter />
          </Button>
          <Printer
            invoiceStatus={selectedMovement.InvoiceStatus}
            transaction={selectedMovement}
          />
        </Row>
        {/* )} */}
      </Col>
    </div>
  );
};

export default MovementDetails;
