import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Row } from "react-onsenui";
import Datepicker from "react-datepicker";
import { FaRegCalendarAlt, FaRegCheckCircle, FaUndoAlt } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/Auth/auth.actions";
import { accountIdSelector } from "../../redux/selectors";
import axios from "../../utils/Axios";
import moment from "moment";
import Loader from "../../components/Loader";
import {
  typeStyle,
  movementTypeValues,
  getMovementTypeValue
} from "./constants";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import { unixToTicks } from "../../utils/unixToTicks";
import excelIcon from "../../assets/images/excel-icon.png";
import DashboardExport from "../Dashboard/DashboardExport";

const Movements = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [movements, setMovements] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalMovs, setTotalMovs] = useState(0);
  const [type, setType] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [balances, setBalances] = useState({
    USD: { Amount: 0 },
    BTC: { Amount: 0 }
  });
  const [msj] = useState({
    msj1: t("Ocurrió un error al cargar los detalles de este movimiento"),
    msj2: t("Ocurrió un error al cargar el balance"),
    msj3: t("Ocurrió un error al cargar las transacciones")
  });
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState({});
  const AccountId = useSelector(accountIdSelector);

  const dispatch = useDispatch();

  const CustomInput = ({ onClick, label, value, className }) => (
    <Button onClick={onClick} className={className}>
      <FaRegCalendarAlt className="dateIcon" /> {value || label}
      <div className="arrow-down" />
    </Button>
  );

  const getDetails = async movement => {
    try {
      setIsLoading(true);
      setSelectedMovement(movement);
      setIsLoading(false);
    } catch (error) {
      NotificationManager.error(msj.msj1);
    }
  };

  const getBalances = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountPositions`,
        {
          OMSId: 1,
          AccountId
        }
      );

      const transformedData = data.reduce((acc, el) => {
        acc[el.ProductSymbol] = el;
        return acc;
      }, {});

      setBalances(transformedData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(msj.msj2);
      }
    }
  };

  const getMovements = async (StartIndex = 0, Depth = pageSize) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountTransactionDetailHistory`,
        {
          OMSId: 1,
          Depth,
          StartIndex: StartIndex * pageSize,
          AccountId,
          StartTimestamp:
            (startDate && unixToTicks(moment(startDate).valueOf())) ||
            undefined,
          EndTimestamp:
            (endDate && unixToTicks(moment(endDate).valueOf())) || undefined,
          Description: type
        }
      );

      setMovements(data);
      setTotalMovs(data.length);
      setResetPaginationToggle(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(msj.msj3);
      }
    }
  };

  const movementIsTransfer = movement => {
    return movement.TransactionRefType === "Transfer";
  };

  const openDetails = movement => {
    setShowDetails(true);
    getDetails(movement);
  };

  const onCloseDetails = () => {
    setSelectedMovement({});
    setShowDetails(false);
  };

  const rows = movements.map((movement, i) => {
    let initialAmount = movement.Amount;
    let amount = movement.Amount;
    let product = movement.ProductId;

    if (movement.ConversionDetails.length) {
      let [
        startingProduct,
        endingProduct,
        endingAmount
      ] = movement.ConversionDetails.split("|");
      amount = endingAmount;
      product = movement.ProductId === 1 ? 2 : 1;
    }

    return (
      <div
        className={`table-cell ${
          movements.length === 1
            ? "row-header-bottom"
            : i === 0
              ? "row-header"
              : i === movements.length - 1
                ? "row-bottom"
                : ""
        }`}
        onClick={() => openDetails(movement)}>
        <div className="row-cell">
          {getMovementTypeValue(movement)?.icon ? (
            <div className={`square ${product === 1 ? "blue" : "orange"}`}>
              {getMovementTypeValue(movement)?.icon}
            </div>
          ) : (
            <div className="square grey">
              <div className="star" />
            </div>
          )}
          <div className="desc">
            {movement.ConversionDetails.length ? (
              <>
                <div className="type date">
                  {t("Convertiste")}{" "}
                  {product === 2
                    ? t("Dólares a Bitcoin")
                    : t("Bitcoin a Dólares")}
                </div>
              </>
            ) : null}
            <div className="type">{getMovementTypeValue(movement)?.label}</div>
            <div className="date">
              {moment(movement.TimeStamp).format("DD/MM/YY hh:mma")}
            </div>
          </div>
          <div className="desc uuid">
            <div className="type">{movement.TransactionId}</div>
          </div>
          <div className="amount">
            <div className="amount-usd">
              {amount > 0 ? "+$" : "-$"}
              {product === 1
                ? formatUSDNumber(Math.abs(amount))
                : formatUSDNumber(Math.abs(movement.NotionalAmount))}{" "}
              USD
            </div>
            <div className="amount-btc">
              {product === 2
                ? formatNumber(amount)
                : movement.ConversionDetails.length
                  ? formatNumber(movement.Amount)
                  : formatNumber(
                      movement.NotionalAmount / balances?.BTC?.NotionalRate
                    )}{" "}
              BTC
            </div>
          </div>
          <div
            className="status-mark"
            style={{ color: typeStyle[movement.Status]?.color }}>
            {typeStyle[movement.Status]?.icon}
          </div>
        </div>
        {i < movements.length - 1 && <div className="row-separator" />}
      </div>
    );
  });

  useEffect(() => {
    getBalances();
    getMovements();
  }, []);

  useEffect(
    () => {
      getMovements();
    },
    [pageSize, startDate, endDate, type]
  );

  useEffect(
    () => {
      setResetPaginationToggle(true);
      page === 1 && getMovements();
    },
    [type]
  );

  let initialAmount = selectedMovement.Amount;
  let amount = selectedMovement.Amount;
  let product = selectedMovement.ProductId;

  if (selectedMovement?.ConversionDetails?.length) {
    let [
      startingProduct,
      endingProduct,
      endingAmount
    ] = selectedMovement.ConversionDetails.split("|");
    amount = endingAmount;
    product = selectedMovement.ProductId === 1 ? 2 : 1;
  }

  const [isExportOpen, setIsExportOpen] = useState(false);

  return (
    <div className="content transactions">
      <Modal isOpen={showDetails}>
        <div className="card mh-400">
          {!isLoading ? (
            <div className="card__content flex-center flex-col">
              <h2 className="card__title">
                {getMovementTypeValue(selectedMovement)?.label}
              </h2>
              <h1 className="card-title">
                <div className="amount-usd">
                  {selectedMovement.Amount > 0 ? "+" : "-"} $
                  {product === 1
                    ? formatUSDNumber(Math.abs(amount))
                    : formatUSDNumber(
                        Math.abs(selectedMovement.NotionalAmount)
                      )}{" "}
                  USD
                </div>
              </h1>
              <div className="amount-btc">
                {product === 2
                  ? formatNumber(amount)
                  : selectedMovement?.ConversionDetails?.length
                    ? formatNumber(selectedMovement.Amount)
                    : balances?.BTC?.NotionalRate
                      ? formatNumber(
                          selectedMovement.NotionalAmount /
                            balances?.BTC?.NotionalRate
                        )
                      : 0}{" "}
                BTC
              </div>
              <div
                className="status-mark"
                style={{ color: typeStyle[selectedMovement.Status]?.color }}>
                {typeStyle[selectedMovement.Status]?.icon}
              </div>
              <div className="date mb-1">
                {moment(selectedMovement?.TimeStamp).format("DD/MM/YY hh:mma")}
              </div>
              {movementIsTransfer(selectedMovement) ? (
                <>
                  <div className="flex-center flex-col">
                    <p className="mb-0">
                      <strong>{t("Invoice ID:")} </strong>{" "}
                      {selectedMovement.TransactionRefId}
                    </p>
                    <p className="mb-0">
                      <strong>{t("Desde")}: </strong>
                    </p>
                    <p className="mb-0">{selectedMovement.From}</p>
                    <p>
                      {/* <strong>{t("DNI:")} </strong>{" "} */}
                      <strong>{t("Account ID:")} </strong>{" "}
                      {selectedMovement.CounterpartyAccountId}
                    </p>
                  </div>
                  <div className="flex-center flex-col">
                    <p className="mb-0">
                      <strong>{t("Para")}: </strong>
                    </p>
                    <p className="mb-0">{selectedMovement.To}</p>
                    <p>
                      {/* <strong>{t("DNI:")} </strong>{" "} */}
                      <strong>{t("Account ID:")} </strong>{" "}
                      {selectedMovement.AccountId}
                    </p>
                  </div>
                </>
              ) : selectedMovement.type === "pay" ? (
                <div className="flex-center flex-col">
                  <p>
                    <strong>{t("Monto cancelado:")} </strong>{" "}
                    {selectedMovement.amount_paid}
                  </p>
                  <p>
                    <strong>{t("Monto pendiente:")} </strong>{" "}
                    {selectedMovement.amount_pending_to_paid}
                  </p>
                  <p>
                    <strong>{t("Monto fiat:")} </strong>{" "}
                    {selectedMovement.string_fiat_amount_to_commerce}
                  </p>
                  <p>
                    <strong>{t("Monto crypto:")} </strong>{" "}
                    {selectedMovement.string_crypto_amount_to_commerce}
                  </p>
                  <p>
                    <strong>{t("Usuario:")} </strong>{" "}
                    {selectedMovement.user.first_name}{" "}
                    {selectedMovement.user.last_name}
                  </p>
                </div>
              ) : null}
              <Button
                className="button-blue inline-button text-align-center mt-2"
                onClick={() => onCloseDetails()}>
                {t("Cerrar")}
              </Button>
            </div>
          ) : (
            <div className="card__content flex-center flex-col">
              <Loader className="loader-blue" />
            </div>
          )}
        </div>
      </Modal>
      <div className="content-title">{t("Movimientos")}</div>
      <div className="usd-btc usd">
        <div className="usd-btc-title">USD</div>
        <div className="usd-btc-amount">
          ${formatUSDNumber(balances?.USD?.Amount)}
        </div>
        <div className="usd-btc-result">
          {balances?.BTC?.NotionalRate
            ? formatNumber(balances?.USD?.Amount / balances?.BTC?.NotionalRate)
            : 0}{" "}
          BTC
        </div>
      </div>
      <div className="usd-btc btc">
        <div className="usd-btc-title">BTC</div>
        <div className="usd-btc-amount">
          {formatNumber(balances?.BTC?.Amount)}
        </div>
        <div className="usd-btc-result">
          {balances?.BTC?.NotionalRate
            ? formatUSDNumber(
                balances?.BTC?.Amount * balances?.BTC?.NotionalRate
              )
            : 0}{" "}
          USD
        </div>
      </div>
      <div>
        <div className="button-blue select-type">
          <FaRegCheckCircle className="type-icon" />
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}>
            <option value="">{t("Tipo")}</option>
            {Object.keys(movementTypeValues).map((key, index) => (
              <option value={key}>
                {t(`${movementTypeValues[key].label}`)}
              </option>
            ))}
          </Select>
        </div>
        <Datepicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          maxDate={endDate || moment.now()}
          dateFormat="dd/MM/yyyy"
          className="middle-picker"
          showTimeSelect
          customInput={<CustomInput label="Desde" className="button-blue" />}
        />
        <Datepicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          minDate={startDate}
          maxDate={moment.now()}
          dateFormat="dd/MM/yyyy"
          showTimeSelect
          customInput={
            <CustomInput label={t("Hasta")} className="button-blue" />
          }
        />
        <div
          className="button-blue"
          style={{
            margin: "0 10px",
            marginTop: "15px",
            width: "170px !important",
            display: "inline-block",
            verticalAlign: "top",
            paddingTop: 0
          }}>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              width: "170px !important",
              justifyContent: "center"
            }}
            onClick={getMovements}>
            <FaUndoAlt className="type-icon" style={{ marginRight: "1rem" }} />
            <span>{t("Actualizar")}</span>
          </div>
        </div>
        <Row className="flex-center actions-row">
          <div className="report-btn-container">
            <div className="flex-container">
              <img
                className="reportImg"
                alt="excelIcon"
                src={excelIcon}
                width={16}
                height={16}
              />
              <Button
                onClick={() => {
                  setIsExportOpen(true);
                }}
                className="report-btn pointer">
                {t("Generar Reporte")}
              </Button>
            </div>
          </div>
        </Row>
        <DataTable
          pagination
          paginationServer
          progressPending={isLoading}
          columns={[{ cell: row => row }]}
          style={{ marginTop: "10px", borderRadius: "20px" }}
          data={rows}
          customStyles={{
            head: {
              style: {
                backgroundColor: "black",
                border: "none"
              }
            },
            rows: {
              style: {
                border: "none !important",
                padding: "0 calc(48% - 22rem)"
              }
            },
            cells: {
              style: {
                display: "block"
              }
            },
            pagination: {
              style: {
                border: "none",
                color: "black",
                fontSize: "15px",
                justifyContent: "center"
              }
            }
          }}
          paginationComponentOptions={{
            rowsPerPageText: t("Filas por página"),
            rangeSeparatorText: t("de"),
            noRowsPerPage: true,
            selectAllRowsItem: false
          }}
          paginationResetDefaultPage={resetPaginationToggle}
          paginationTotalRows={pageSize * page + 1}
          onChangePage={Page => {
            if (rows.length >= pageSize || Page <= page) {
              getMovements(Page - 1);
              setPage(Page);
            }
          }}
          noDataComponent={t("No hay transacciones")}
          onChangeRowsPerPage={setPageSize}
          noTableHead
          paginationIconLastPage={() => {}}
        />
      </div>
      <DashboardExport
        isOpen={isExportOpen}
        setIsOpen={value => setIsExportOpen(value)}
      />
    </div>
  );
};

export default Movements;
