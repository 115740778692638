/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Col, Row, Button } from "react-onsenui";
import QrReader from "react-qr-scanner";
import { useTranslation } from "react-i18next";

const previewStyle = {
  height: 300,
  width: 400
};

const ConfirmSend = ({ amount, setStep }) => {
  const { t } = useTranslation();
  const [currencyBTC, setCurrencyBTC] = useState(true);
  const delay = 100;

  const handleScan = data => {
    console.log(data);
  };
  const handleError = err => {
    console.error(err);
  };

  return (
    <>
      <Col className="input-col input-amount input-qr" width="60%">
        <Row className="row-amount row-qr">
          <div className="qr-corner-frame1">
            <div className="qr-corner-frame2">
              <div className="qr-frame">
                <QrReader
                  delay={delay}
                  style={previewStyle}
                  onError={data => handleError(data)}
                  onScan={data => handleScan(data)}
                />
              </div>
            </div>
          </div>
        </Row>
        <Row className="row-size row-qr-desc confirm-send row-margin-top">
          {t("Escanear una solicitud de pago a enviar dinero")}
        </Row>
      </Col>
      <Col className="btn-col only-button qr-button" width="40%">
        <Button
          className="button-blue inline-button pointer center-inline-button"
          onClick={() => setStep("confirm")}
        >
          {t("Pagar")}
        </Button>
      </Col>
    </>
  );
};

export default ConfirmSend;
