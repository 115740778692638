const translationMovements = {
    "TimeStamp": "Marca Tiempo",
    "Description": "Descripción",
    "Product": "Producto",
    "From": "Desde",
    "To": "Hacia",
    "Amount": "Monto",
    "Balance": "Saldo",
    "Status": "Estatus",
    "TransactionId": "No. Transacción",
    "AccountId": "No. Cuenta",
    "CounterpartyAccountId": "No. contraparte",
    "Details": "Detalles",
    "TransactionRefType": "Ref. Tipo referencia",
    "TransactionRefId": "Ref. No. referencia",
    "TransactionType": "Tipo transacción",
    "NotionalAmount": "Cantidad Teórica",
    "NotionalProductId": "No. Producto Nocional",
    "ConversionDetails": "Detalles de conversión",
}
export default translationMovements;