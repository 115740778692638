import { combineReducers } from "redux";
import authReducer from "./Auth/auth.reducer";
import paymentReducer from "./ProcessPayment/processPayment.reducer"

const rootReducer = combineReducers({
  auth: authReducer,
  paymentMethod: paymentReducer,
});

export default rootReducer;
