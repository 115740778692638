import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Radio,
  Button,
  Icon,
  Input,
  Select,
  Modal
} from "react-onsenui";
import DataTable from "react-data-table-component";
import { ErrorMessage } from "@hookform/error-message";
import { IoTrashSharp } from "react-icons/io5";
import { RiBankLine, RiFileList3Line } from "react-icons/ri";
import axios from "../../utils/Axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { NotificationManager } from "react-notifications";
import { logout, updateFiatConversion } from "../../redux/Auth/auth.actions";
import {
  accountIdSelector,
  userIdSelector,
  userFullNameSelector
} from "../../redux/selectors";
import { useForm } from "react-hook-form";
import { BANKS } from "./banks";
import { CONVERT_OPTIONS } from "../../constants";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import { qrCodeNameSelector } from "../../redux/selectors";
import { useTranslation } from "react-i18next";

const Settings = ({ history }) => {
  const { t } = useTranslation();
  const AccountName = useSelector(qrCodeNameSelector);
  const [showBankAccount, setShowBankAccount] = useState(false);
  // const [switched, setSwitched] = useState(
  //   commerce.fiat_withdraw === "100" ? true : false
  // );
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all"
  });
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [bank, setBank] = useState("");
  const [data, setData] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountDeleting, setAccountDeleting] = useState(0);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [amount, setAmount] = useState("");
  const [balances, setBalances] = useState({});
  const [msj] = useState({
    msj1: t("Ocurrió un error al obtener las cuentas"),
    msj2: t("Ocurrió un error al cargar el balance"),
    msj3: t("Esta cuenta ya existe."),
    msj4: t("Ocurrió un error al crear la cuenta"),
    msj5: t("Se ha creado la cuenta con éxito"),
    msj6: t("Se ha cambiado la configuración con éxito"),
    msj7: t("Ocurrió un error al cambiar la configuración"),
    msj8: t("Se ha eliminado la cuenta con éxito"),
    msj9: t("Ocurrió un error al eliminar la cuenta")
  });

  const { FirstName, MiddleName, FirstLastName, SecondLastName } = useSelector(
    state => state.auth.user
  );

  const [businessName, setBusinessName] = useState(AccountName);
  const [firstName, setFirstName] = useState(FirstName);
  const [middleName, setMiddleName] = useState(MiddleName);
  const [surname1, setSurname1] = useState(FirstLastName);
  const [surname2, setSurname2] = useState(SecondLastName);

  const dispatch = useDispatch();

  const accountTypesValues = {
    saving: "Ahorro",
    checking: "Corriente"
  };

  useEffect(() => {
    getBankAccounts();
    getBalances();
  }, []);

  const getBankAccounts = async () => {
    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountWithdrawInfos`,
        {
          OMSId: 1,
          AccountId
        }
      );
      setData(data);
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(msj.msj1);
      }
      console.log(error);
    }
  };

  const AccountId = useSelector(accountIdSelector);
  const UserId = useSelector(userIdSelector);
  const FullName = useSelector(userFullNameSelector);

  const {
    user: { AvatarImage, Name }
  } = useSelector(state => state.auth);

  const getBalances = async () => {
    try {
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountPositions`,
        {
          OMSId: 1,
          AccountId
        }
      );

      const transformedData = data.reduce((acc, el) => {
        acc[el.ProductSymbol] = el;
        return acc;
      }, {});

      setBalances(transformedData);
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(msj.msj2);
      }
    }
  };

  const getBalance = code => {
    if (!balances[code])
      return {
        Amount: 0,
        code: code === "USD" ? "BTC" : "USD",
        equivalent: 0
      };
    return balances[code];
  };

  const resetFields = () => {
    setNumber("");
    setType("");
    setBank("");
  };

  const accountExist = account => {
    return (
      data.filter(e => {
        let jsonFields;
        try {
          jsonFields = JSON.parse(e.AccountIdentifier);
        } catch (e) {
          console.log(e);
        }

        return (
          e.Name === BANKS.find(i => i.id === parseInt(account.bank)).name &&
          jsonFields.Number === account.number &&
          jsonFields.Type === account.type
        );
      }).length > 0
    );
  };

  const onAddBankAccount = async () => {
    setLoadingAdd(true);

    const payload = {
      number,
      type,
      bank,
      currency
    };

    if (accountExist(payload)) {
      NotificationManager.error(msj.msj3);
      setLoadingAdd(false);
      return;
    }

    const Bank = BANKS[parseInt(bank) - 1];
    setError(false);

    try {
      const { data } = await axios.post(
        `${window.config.serverUri}CreateAccountWithdrawInfo`,
        {
          OMSId: 1,
          ProductId: 1,
          AccountId,
          Name: Bank.name,
          AccountIdentifier: JSON.stringify({
            DestinationAccountOwnerDocumentNumber: localStorage
              .getItem("UserIdentifier")
              .replaceAll("-", ""),
            Description: "",
            DestinationOwnerTypeCode: "J",
            TypeCodeDocument: "2",
            BankCode: Bank.code,
            DestinationAccountNumber: number,
            DestinationAccountType: type === "saving" ? "01" : "02",
            DestinationAccountOwnerFirstName: businessName,
            DestinationAccountOwnerMiddleName: null,
            DestinationAccountOwnerSurname1: null,
            DestinationAccountOwnerSurname2: null
          }),
          Verified: true
        }
      );

      if (data.result === false) {
        NotificationManager.error(msj.msj4);
      } else {
        resetFields();
        getBankAccounts();
        setShowBankAccount(false);
        NotificationManager.success(msj.msj5);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(msj.msj4);
      }
    } finally {
      setLoadingAdd(false);
    }
  };

  const onWithdraw = async () => {
    const payload = {
      ...selectedAccount,
      account: selectedAccount.id,
      currency: "USD",
      amount: parseFloat(amount)
    };
    history.push("/withdraw", {
      payload,
      selectedAccount,
      balance: formatUSDNumber(
        getBalance("USD").Amount - getBalance("USD").Hold
      )
    });
  };

  const onChangeConversion = async option => {
    try {
      if (!loading) {
        setLoading(true);
        const { data } = await axios.post(
          `${window.config.serverUri}SetUserProfile`,
          {
            UserId,
            AccountId,
            AvatarImage,
            Name,
            FullName,
            ConvertCurrency: option,
            UserProfileType: "MerchantId"
          }
        );

        if (data.result) {
          dispatch(updateFiatConversion(option));
          NotificationManager.success(msj.msj6);
        }
      }
    } catch (error) {
      NotificationManager.error(msj.msj7);
    }
    setLoading(false);
  };

  const onDeleteBankAccount = async ({ AccountWithdrawInfoId }) => {
    if (!loadingDel) {
      setLoadingDel(true);
      setAccountDeleting(AccountWithdrawInfoId);
      try {
        const { data } = await axios.post(
          `${window.config.serverUri}DeleteAccountWithdrawInfo`,
          {
            OMSId: 1,
            AccountId,
            AccountWithdrawInfoId: AccountWithdrawInfoId
          }
        );
        getBankAccounts();
        NotificationManager.success(msj.msj8);
      } catch (error) {
        console.log(error);
        if (error.response.status === 404) {
          dispatch(logout());
        } else {
          NotificationManager.error(msj.msj9);
        }
      } finally {
        setLoadingDel(false);
        setAccountDeleting(0);
      }
    }
  };

  const onOpenModal = row => {
    setSelectedAccount(row);
    setShowWithdraw(true);
  };

  const rows = data.map((row, i) => {
    let data;
    let acct;
    try {
      data = JSON.parse(row.AccountIdentifier);
      acct = data?.DestinationAccountNumber || data?.accountNumber;
    } catch (e) {
      console.log(e);
    }
    return (
      <div className="terminals-form" key={i}>
        <Row className="form-row">
          <Col width="20%">
            <div
              className={`action blue-action ${
                row.Verified ? "" : "blue-inactive"
              }`}
              onClick={() => (row.Verified ? onOpenModal(row) : {})}>
              <div className="form-label form-icon" style={{ display: "flex" }}>
                <RiBankLine /> {t("Solicitar Retiro")}
              </div>
            </div>
          </Col>
          <Col width="20%">
            <div className="form-col-input">
              <div className="form-label">
                {t("Banco")}
                {":"}
              </div>
              <div className="form-cell">{row.Name}</div>
            </div>
          </Col>
          <Col width="15%">
            <div className="form-col-input">
              <div className="form-label">
                {t("Tipo")}
                {":"}
              </div>
              <div className="form-cell">
                {t(
                  accountTypesValues[
                    data?.DestinationAccountType === "01" ||
                    data?.accountType === "01"
                      ? "saving"
                      : "checking"
                  ]
                )}
              </div>
            </div>
          </Col>
          <Col width="15%">
            <div className="form-col-input">
              <div className="form-label">
                {t("Estatus")}
                {":"}
              </div>
              <div className="form-cell">
                {row.Verified ? t("activo") : t("inactivo")}
              </div>
            </div>
          </Col>
          <Col width="15%">
            <div className="form-col-input">
              <div className="form-label">
                {t("Número")}
                {":"}
              </div>
              <div className="form-cell">
                {" "}
                **** {acct?.slice(acct?.length - 4, acct?.length)}
              </div>
            </div>
          </Col>
          <Col width="60px">
            <div
              className="action"
              onClick={() => onDeleteBankAccount(row)}
              style={{ cursor: "pointer" }}>
              <div className="form-label form-icon">
                {loadingDel && accountDeleting === row.AccountWithdrawInfoId ? (
                  <Loader className="form-col-loading" />
                ) : (
                  <IoTrashSharp className="form-col-delete" />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  const {
    user: { ConvertCurrency = CONVERT_OPTIONS.none }
  } = useSelector(state => state.auth);

  let selectedAccountData;
  let selectedAccountNumber;
  if (selectedAccount) {
    try {
      selectedAccountData = JSON.parse(selectedAccount.AccountIdentifier);
      selectedAccountNumber =
        selectedAccountData?.DestinationAccountNumber ||
        selectedAccountData?.accountNumber;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="content content-settings">
      <Modal isOpen={showWithdraw}>
        <div className="card dialog-center">
          <div className="card__content flex-center flex-col">
            <h4>{t("Vas a retirar desde tu cuenta")}</h4>
            <h2>
              ****{" "}
              {selectedAccountNumber?.slice(
                selectedAccountNumber?.length - 4,
                selectedAccountNumber?.length
              )}
            </h2>
            <h4>{selectedAccount?.Name}</h4>
            <input
              className="form-input"
              style={{ lineHeight: "40px", width: "43%" }}
              value={amount}
              maxLength="20"
              onChange={e => {
                setAmount(e.target.value.replace(/[^0-9.]/g, ""));
              }}
            />
            <Row className="flex-center">
              <Button
                className="button-blue inline-button text-align-center mt-2"
                onClick={() => onWithdraw()}
                disabled={loading || !amount}>
                {loading ? <Loader /> : t("Retirar")}
              </Button>
              <Button
                className="button-blue inline-button text-align-center mt-2"
                onClick={() => setShowWithdraw(false)}>
                {t("Cancelar")}
              </Button>
            </Row>
          </div>
        </div>
      </Modal>
      <div className="content-settings-header">
        <div className="title-section">
          <div className="content-title flex-center">
            {t("Configuraciones")}
          </div>
        </div>
        <div className="available-section-wrapper">
          <div
            className="available-section"
            style={{
              padding: "10px",
              backgroundColor: "#010D4C",
              borderRadius: "15px"
            }}>
            <div className="available-amount">
              <a
                rel="noreferrer"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer"
                }}
                href="https://chivowallet.com/recarga.html">
                {" "}
                Recarga tu Chivo Wallet{" "}
              </a>
            </div>
          </div>
          <div className="available-section">
            <div className="available-title">{t("Disponible")}</div>
            <div className="available-amount">
              ${formatUSDNumber(
                getBalance("USD").Amount - getBalance("USD").Hold
              )}{" "}
              USD
            </div>
          </div>
          <div className="withdrawals-section">
            <div
              className="withdrawals-button"
              onClick={() => history.push("/withdrawals")}>
              <RiFileList3Line /> {t("Últimos retiros")}
            </div>
          </div>
        </div>
      </div>
      <div className="content-title content-subtitle">
        {t("Conversión automática")}
      </div>
      <div className="card-section flex-center">
        <div className="card-switch flex-center">
          <span className="mv-05">
            <Radio
              checked={
                ConvertCurrency === CONVERT_OPTIONS.none ||
                (ConvertCurrency !== CONVERT_OPTIONS.btc &&
                  ConvertCurrency !== CONVERT_OPTIONS.usd)
              }
              onChange={event => onChangeConversion(CONVERT_OPTIONS.none)}
              modifier="material"
            />{" "}
            {t("No convertir")}
          </span>
          <span className="mv-05">
            <Radio
              checked={ConvertCurrency === CONVERT_OPTIONS.usd}
              onChange={event => onChangeConversion(CONVERT_OPTIONS.usd)}
              modifier="material"
            />{" "}
            {t("Convertir a dolares")}
          </span>
          <span className="mv-05">
            <Radio
              checked={ConvertCurrency === CONVERT_OPTIONS.btc}
              onChange={event => onChangeConversion(CONVERT_OPTIONS.btc)}
              modifier="material"
            />{" "}
            {t("Convertir a Bitcoin")}
          </span>
        </div>
        <div className="card-description">
          <p className="title">{t("Información")}</p>
          {ConvertCurrency === CONVERT_OPTIONS.none ||
          (ConvertCurrency !== CONVERT_OPTIONS.btc &&
            ConvertCurrency !== CONVERT_OPTIONS.usd) ? (
            <>
              <p>
                {t(
                  "Al seleccionar esta opción, no habrá conversión automática."
                )}
              </p>{" "}
              <br />
              <p>
                {t(
                  "Si quieres que el dinero que recibas se convierta automáticamente, elige otra de las opciones."
                )}
              </p>
            </>
          ) : (
            <>
              <p>
                {t(
                  "Al seleccionar esta opción, todo el dinero que recibas sera automáticamente convertido a"
                )}{" "}
                {ConvertCurrency === CONVERT_OPTIONS.usd
                  ? t("dolares")
                  : "Bitcoin"}
                .
              </p>{" "}
            </>
          )}
        </div>
      </div>
      <div className="content-title content-subtitle">
        {t("Cuentas bancarias")}
      </div>
      <DataTable
        pagination
        columns={[{ cell: row => row }]}
        data={rows}
        customStyles={{
          rows: {
            style: {
              border: "none !important"
            }
          },
          cells: {
            style: {
              display: "block"
            }
          },
          pagination: {
            style: {
              border: "none",
              paddingRight: "5vw",
              color: "black",
              fontSize: "15px"
            }
          }
        }}
        paginationComponentOptions={{
          rowsPerPageText: t("Filas por página"),
          rangeSeparatorText: t("de"),
          noRowsPerPage: true
        }}
        noDataComponent={t("No hay transacciones")}
        noTableHead
      />
      <Button
        className="button-blue button-add"
        onClick={() =>
          !(data.length >= 3) && setShowBankAccount(!showBankAccount)
        }
        disabled={data.length >= 3}>
        <Icon
          className="button-icon"
          size={{ default: 30, material: 30 }}
          icon="md-plus-circle"
        />
        {t("Agregar cuenta bancaria")}
      </Button>
      {showBankAccount && (
        <div className="terminals-form add-form">
          <Row className="form-row">
            <Col width="40%">
              <div
                className={`form-col-input col-addaccount${
                  !businessName ? " is-required" : ""
                }`}>
                <div className="form-label">
                  {t("Nombre")}
                  {":"}
                </div>
                <Input
                  className="form-input"
                  value={businessName}
                  onChange={e => setBusinessName(e.target.value)}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row className="form-row">
            <Col width="40%">
              <div
                className={`form-col-input col-addaccount${
                  !bank ? " is-required" : ""
                }`}>
                <Select
                  className="form-input"
                  value={bank}
                  onChange={e => {
                    setBank(parseInt(e.target.value) || "");
                    setNumber("");
                  }}>
                  <option value="">{t("Banco")}</option>
                  {BANKS.map(
                    bank =>
                      bank.active && (
                        <option value={bank.id}>{bank.name}</option>
                      )
                  )}
                </Select>
              </div>
            </Col>
            <Col width="40%">
              <div
                className={`form-col-input col-addaccount${
                  !type ? " is-required" : ""
                }`}>
                <Select
                  className="form-input"
                  value={type}
                  onChange={e => setType(e.target.value)}>
                  <option value="">{t("Tipo de cuenta")}</option>
                  {Object.keys(accountTypesValues).map(key => (
                    <option value={key}>{t(accountTypesValues[key])}</option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="form-row">
            <Col width="40%">
              <div
                className={`form-col-input col-addaccount${
                  !number ? " is-required" : ""
                }`}>
                <div className="form-label">{t("Número de cuenta:")}</div>
                <input
                  className="form-input"
                  value={number}
                  minLength={bank ? BANKS.find(i => i.id === bank).min : 8}
                  maxLength={bank ? BANKS.find(i => i.id === bank).max : 20}
                  onChange={e => {
                    let number;
                    if (Number.isInteger(Number(e.target.value))) {
                      number = `${e.target.value}`.trim();
                      setNumber(number);
                    }
                  }}
                />
              </div>
            </Col>
            <Col width="40%">
              <div className="form-col-input col-addaccount">
                <div className="form-label">
                  {t("Moneda")}
                  {":"}
                </div>
                <Input
                  className="form-input"
                  value={currency}
                  disabled={true}
                  style={{ color: "black !important", opacity: "1" }}
                />
              </div>
            </Col>
          </Row>
          <div className="form-buttons">
            <Button
              className="button-blue inline-button"
              disabled={
                !number ||
                !type ||
                !currency ||
                !(
                  bank && BANKS.find(i => i.id === bank).min <= number.length
                ) ||
                loadingAdd
              }
              onClick={handleSubmit(onAddBankAccount)}>
              {loadingAdd ? <Loader /> : t("Agregar cuenta bancaria")}
            </Button>
            <Button
              className="button-blue inline-button"
              onClick={() => {
                setNumber("");
                setType("");
                setCurrency("USD");
                setBank("");
                setShowBankAccount(false);
              }}>
              {t("Cancelar")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
