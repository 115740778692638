/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Col, Row, Button } from "react-onsenui";
import { useTranslation } from "react-i18next";

const ConfirmSend = ({ amount, setStep }) => {
  const { t } = useTranslation();
  const [currencyBTC, setCurrencyBTC] = useState(true);
  return (
    <>
      <div className="title">{t("Confirma tu pago")}</div>
      <Row>
        <Col className="input-col input-amount">
          <Row className="row-amount">
            <div className="circle-amount confirm-send">
              <div className="amount-money">${amount}</div>
              <div className="currency">BTC</div>
            </div>
          </Row>
          <Row className="row-size confirm-send row-margin-top">
            <div style={{ backgroundColor: "#E7B403" }} className="color-box" />
            <div className="name-option">{t("Patricia Portillo")}</div>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-end w-90">
        <Button
          className="button-blue inline-button pointer center-inline-button"
          onClick={() => setStep("success")}
        >
          {t("Confirmar")}
        </Button>
      </Row>
    </>
  );
};

export default ConfirmSend;
