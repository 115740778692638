const translationsTranscations = {
    "InvoiceId": "No. factura",
    "MerchantId": "No. comerciante",
    "MerchantName": "Nombre comerciante",
    "MerchantIdentifier": "Identificador comerciante",
    "AccountId": "No. cuenta",
    "InvoiceStatus": "Estado factura",
    "ProductSymbol": "Producto Símbolo",
    "ProductNetwork": "Producto Red",
    "ChargeDetail": "CargoDetalle",
    "Amount": "Monto",
    "NotionalAmount": "Monto nominal",
    "NotionalProductId": "Producto nominal",
    "AmountReceived": "Monto recibido",
    "NotionalAmountReceived": "Monto nominal recibido",
    "PaymentReferenceId": "No. referencia de pago",
    "PaymentMethod": "Método de pago",
    "PaymentDetails": "Detalles de pago",
    "TransactionId": "No. transacción",
    "CreatedDT": "Fecha creación",
    "UpdatedDT": "Fecha actualización",
    "CreatedBy": "Creado por",
    "CreatedByName": "Nombre creador",
    "UpdatedBy": "Actualizado por",
    "Product": "Producto",
}
export default translationsTranscations;