import React, { useState } from "react";
import { Row } from "react-onsenui";
import ConfirmWithdraw from "./ConfirmWithdraw";
import SuccessWithdraw from "./SuccessWithdraw";
import { useTranslation } from "react-i18next";

const Withdraw = ({ history }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState("");

  return (
    <Row className="withdraw-content">
      <div className="withdraw-content-container">
        <Row>
          <div className="withdraw-content-header">
            <div className="withdraw-title">{t("Retiro a banco")}</div>
            <div className="available-section">
              <div className="available-title">{t("Disponible")}</div>
              <div className="available-amount">
                ${history.location.state.balance} USD
              </div>
            </div>
          </div>
        </Row>
        <Row>
          {step === "success" ? (
            <SuccessWithdraw
              history={history}
              setStep={setStep}
              payload={history.location.state.payload}
              account={history.location.state.selectedAccount}
            />
          ) : (
            <ConfirmWithdraw
              setStep={setStep}
              payload={history.location.state.payload}
              account={history.location.state.selectedAccount}
            />
          )}
        </Row>
      </div>
    </Row>
  );
};

export default Withdraw;
