import React, { useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { confirmPayment } from "../../redux/ProcessPayment/processPayment.actions";

const PaymentConfirmation = ({ history }) => {
  const { t } = useTranslation();
  const { payment } = history.location.state;
  const dispatch = useDispatch();

  useEffect(() => {
    return function reload() {
      dispatch(confirmPayment());
    };
  }, []);

  const paymentIsComplete =
    Number(payment.amount_to_change_pending) === 0 && !payment.amount_exceeded;

  return (
    <div className="payment-confirmation process-payments">
      {payment.status === "approved" ? (
        <div className="message">
          {t("Pago")}{" "}
          {paymentIsComplete
            ? t("recibido")
            : payment.amount_exceeded
            ? t("excedido recibido")
            : t("parcial recibido")}
        </div>
      ) : (
        <div className="message">{t("Pago fallido")}</div>
      )}
      <div className="rounded-section">
        <div className="confirmation-amount">$ {payment.amount_paid}</div>
        <div className="confirmation-methods">USD</div>
      </div>
      {payment.status === "approved" ? (
        paymentIsComplete ? (
          <FaCheckCircle size="90" color="#64FF33" />
        ) : (
          <>
            <FaCheckCircle size="90" color="#FFC300" />
            {!payment.amount_exceeded && (
              <>
                <p>
                  <strong>{t("Restante:")} </strong>{" "}
                  {payment.amount_to_change_pending} USD
                </p>
                <Link
                  to={{
                    pathname: "/payments",
                    state: {
                      preamount: payment.amount_to_change_pending
                    }
                  }}
                >
                  {t("¿Generar nuevo pago?")}
                </Link>
              </>
            )}
          </>
        )
      ) : (
        <FaTimesCircle size="90" color="#E90F02" />
      )}
    </div>
  );
};

export default PaymentConfirmation;
