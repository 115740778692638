import { Col, Row, Button } from "react-onsenui";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";

const OTPInput = ({ OTP, setOTP, loading, onVerifyOTP, setStep }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="margin-auth">
        <Row className="justify-content-center text-align-center">
          <Col width="70%">
            <Row className="input-container">
              <input
                autoFocus
                type="text"
                id="otp"
                className="text-input"
                placeholder={t("Código")}
                value={OTP}
                onChange={e =>
                  setOTP(e.target.value.toString().replace(/[^0-9.]/g, ""))
                }
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    onVerifyOTP();
                  }
                }}
              />
            </Row>
            <Button
              onClick={() => onVerifyOTP()}
              modifier="large--cta"
              id="btn-verify-otp"
              className="rounded main-bg pointer margin-auth"
              disabled={loading}>
              <div
                className="header-link margin-null"
                style={{
                  width: "100%",
                  backgroundColor: "#CDD2E4",
                  borderRadius: "15px",
                  color: "black",
                  marginBottom: "15px"
                }}>
                {loading ? <Loader /> : t("Verificar")}
              </div>
            </Button>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "5px"
              }}>
              <Button
                modifier="quiet"
                className="transparent-btn pointer"
                style={{ color: "white" }}
                onClick={() => {
                  setOTP(null);
                  setStep(1);
                }}>
                <Row className="align-items-center">
                  <IoIosArrowBack /> {t("Regresar")}
                </Row>
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OTPInput;
