import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import GridContainer from "./Grid/GridContainer.js";
import GridItem from "./Grid/GridItem.js";
import QRCode from "react-qr-code";
import LogoBlack from "../assets/images/logo_black.png";
import { accountIdSelector, qrCodeNameSelector } from '../redux/selectors';
import { CONVERT_OPTIONS } from '../constants';
import formatNumber, { formatAmount } from "../utils/formatNumber.js";

function walletRenderer(wallet, currency) {
  if (wallet) {
    if (currency === "BTC") {
      return (
        <div>
          <strong>{wallet.substr(0, 5)}</strong>
          {wallet.substring(5, wallet.length - 5)}
          <strong>{wallet.substr(-5, 5)}</strong>
        </div>
      );
    } else {
      return (
        <div>
          <strong>{wallet}</strong>
        </div>
      );
    }
  } else {
    return "";
  }
}

function getExpireTime(transaction) {
  const date = new Date(transaction.CreatedDT);
  date.setSeconds(date.getSeconds() + 1200);
  return date.toLocaleString();
}

const Printer = ({ transaction, invoiceStatus }) => {
  const { t } = useTranslation();
  const { user: { ConvertCurrency = CONVERT_OPTIONS.none }} = useSelector(state => state.auth);
  const AccountId = useSelector(accountIdSelector);
  const Name = useSelector(qrCodeNameSelector);

  let amount = transaction.Amount;
  if (typeof amount === 'string') amount = parseFloat(amount);
  amount = amount.toFixed(8);

  const [QRCODE, depositAddress] = useSelector((state) => {
    let depositAddress = transaction.ChargeDetail;
    let prefix = "";

    const isLightning = transaction.ProductNetwork === "lightning";

    if (transaction.ProductId === 2) {
      prefix = "bitcoin:";
      if (isLightning) {
        prefix = "";
      }
    } else {
      prefix = "usd:";
    }

    let assetAddress = prefix + depositAddress;
    if (!isLightning) {
      assetAddress += `?amount=${amount}`;
      assetAddress += `&message=${transaction.InvoiceId}-${transaction.NotionalAmount}`;
      // assetAddress += `&accountId=${AccountId}`;
      // assetAddress += `&invoiceId=${transaction.InvoiceId}`;
      // assetAddress += `&productId=${transaction.ProductId}`;
      // assetAddress += `&convertCurrency=${ConvertCurrency}`;
      // assetAddress += `&name=${Name}`
    }

    return [assetAddress, depositAddress];
  });

  console.log(QRCODE);

  return (
    <div
      id={"transaction_to_print_" + transaction.InvoiceId}
      style={{ display: "none" }}
    >
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} align="center">
          <img alt="Chivo" src={LogoBlack} width={200} />
        </GridItem>
        <GridItem xs={12} sm={12} align="center">
          <h4>{t("Dirección de pago y monto")}</h4>
        </GridItem>

        <GridItem xs={12} align="center">
          <GridContainer justify="center">
           {invoiceStatus === "Pending" && <GridItem xs={12} align="center">
              <QRCode level="Q" size={200} value={QRCODE} />;
            </GridItem>}
            <GridItem xs={12} sm={12} align="center">
              <hr />
              <span>
                <strong>
                  ${formatAmount(transaction.NotionalAmount)}
                </strong>
                <h6> {t("Monto a pagar en")} {transaction.ProductId === 2 ? "BTC" : "USD"}:</h6>
                <strong>{transaction.ProductId === 1 ? formatAmount(transaction.Amount) : formatNumber(transaction.Amount)}</strong>
              </span>
            </GridItem>
            <GridItem xs={12} sm={12} align="center">
              <span>
                <h4>{t("Wallet:")}</h4>
                {walletRenderer(transaction.ChargeDetail, transaction.ProductId === 2 ? "BTC" : "USD")}
              </span>
            </GridItem>
            <GridItem xs={12} sm={12} align="center">
              <br></br>
              <strong>{t("Este pago expira:")} </strong>
              {getExpireTime(transaction)}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Printer;
