/* eslint-disable react/style-prop-object */
import React from "react";
import { Col, Row, Button } from "react-onsenui";
import { FaRegCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SuccesSend = ({ amount }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="title">{t("Pago realizado")}</div>
      <Row>
        <Col className="input-col input-amount">
          <Row className="row-amount ">
            <div className="circle-amount confirm-send success-send">
              <div className="amount-money">${amount}</div>
              <div className="currency">BTC</div>
            </div>
          </Row>
          <Row className="row-size confirm-send row-margin-top success-send">
            <FaRegCheckCircle />
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-end w-90">
        <Button className="button-blue inline-button pointer center-inline-button">
          {t("Confirmar")}
        </Button>
      </Row>
    </>
  );
};

export default SuccesSend;
