import { Col, Row, Button } from "react-onsenui";
import { ACCOUNT_TYPES } from ".";
import { useTranslation } from "react-i18next";

const TypeSelector = ({ setType }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="margin-auth margin-auth-item">
        <Row className="flex-loginBody justify-content-center text-align-center">
             <div className="login-txt">{t("Iniciar sesión cómo")}</div>
          <Col width="70%">
            <Button
              onClick={() => setType(ACCOUNT_TYPES["commerce"])}
              modifier="large--cta"
              id="commerce"
              className="rounded main-bg pointer margin-auth"
            >
              <div className="header-link margin-null" id="commerce">
                {t("Comercio")}
              </div>
            </Button>
            <Button
              onClick={() => setType(ACCOUNT_TYPES["pos"])}
              modifier="large--cta"
              name="pos"
              className="rounded main-bg pointer margin-auth"
            >
              <div className="header-link margin-null">
                {t("Punto de venta")}
              </div>
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TypeSelector;
