import React, { useState } from "react";
import AddAmount from "./AddAmount";
import ShowQR from "./ShowQR";
import { Row } from "react-onsenui";

const GetPayments = () => {
  const [step, setStep] = useState("");
  const [amount, setAmount] = useState(0);
  const [currencyBTC, setCurrencyBTC] = useState(false);
  return (
    <Row className="get-payment-content">
      {step === "show-qr" ? (
        <ShowQR
          setStep={setStep}
          amount={amount}
          currencyBTC={currencyBTC}
          setCurrencyBTC={setCurrencyBTC}
        />
      ) : (
        <AddAmount
          setStep={setStep}
          amount={amount}
          setAmount={setAmount}
          currencyBTC={currencyBTC}
          setCurrencyBTC={setCurrencyBTC}
        />
      )}
    </Row>
  );
};

export default GetPayments;
