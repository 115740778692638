import React, { useState, useEffect } from "react";
import { Row } from "react-onsenui";
import { FaArrowLeft, FaSpinner } from "react-icons/fa";
import { useSelector } from "react-redux";
import { payInvoice, GetUserIdentifiers } from "../../api/NPE";
import formatNumber from "../../utils/formatNumber";
import axios from "../../utils/Axios";
import { NotificationManager } from "react-notifications";
import Calculator from "../../styles/components/Calculator";

const NpeStep2Unique = ({ code, npeInfo, setNpeStep, setCode, amount, setAmount }) => {
  const [balances, setBalances] = useState({});
  const [loading, setLoading] = useState(false);

  const handleState = (newDigit) => {
    if (newDigit === ".") {
      setAmount((prevState) =>
        prevState.includes(".") ? prevState : prevState + newDigit
      );
    } else if (newDigit === "-") {
      setAmount((prevState) => prevState.slice(0, -1));
    } else {
      setAmount((prevState) => {
        if (prevState === "0") {
          return newDigit;
        }
        if (prevState.includes(".")) {
          if (prevState.split(".")[1].length >= 2) {
            return prevState;
          }
        }

        return prevState + newDigit;
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const ident = await GetUserIdentifiers();
    const identifier = ident.data.filter(
      (el) => el.IdentifierType === "legal rep dui"
    )[0].Identifier;
    const res = await payInvoice({
      codigo: code,
      tipoCodigo: "NPE",
      numeroDocumento: identifier,
      tipoDocumento: "DUI",
      senderAccountId: parseInt(localStorage.getItem("m_acct_id")),
      productId: 1,
      amount: Number(amount),
      notice: "",
    });
    setLoading(false);
    if (res.result) {
      setNpeStep(3);
    } else {
      NotificationManager.error("Datos incorrectos");
    }
  };

  const GetAccountPositions = async () => {
    try {
      const AccountId = parseInt(
        localStorage.getItem("m_acct_id")
      );
      const { data } = await axios.post(
        `${window.config.serverUri}GetAccountPositions`,
        {
          OMSId: 1,
          AccountId,
        }
      );
      const transformedData = data.reduce((acc, el) => {
        acc[el.ProductSymbol] = el;
        return acc;
      }, {});
      setBalances(transformedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getBalance = (code) => {
    if (!balances[code])
      return {
        Amount: 0,
        code: code === "USD" ? "BTC" : "USD",
        equivalent: 0,
      };
    return balances[code];
  };

  useEffect(() => {
    GetAccountPositions();
  }, []);

  return (
    <div className="payment-step">
      <div
        className="message"
        style={{ color: "black " }}
      >{`Disponible ${formatNumber(
        getBalance("USD").Amount - getBalance("USD").Hold
      )}`}</div>
      <div>
        <Row className="amount-send">
          <div style={{ fontSize: "30px", color: "black" }} className="amount-money">
            {amount}
          </div>
        </Row>
        <Row className="amount-send">
          <div style={{ height: "40px", backgroundColor: "#283090" }} className="money-select">USD</div>
        </Row>
      </div>
        <Calculator handleState={handleState} />
      <Row className="amount-send" style={{ marginTop: "20px" }}>
        <div
          onClick={() => {
            setNpeStep(1);
            setCode("");
          }}
          style={{
            cursor: "pointer",
            fontSize: "1rem",
            height: "40px",
            color: "black",
            backgroundColor: "#E6E6E6",
          }}
          className="amount-money"
        >
          Anterior
        </div>
        <div
          onClick={handleSubmit}
          style={{
            cursor: "pointer",
            fontSize: "1rem",
            height: "40px",
            color: "white",
            backgroundColor: "#010D4C",
          }}
          className="amount-money"
        >
          Confirmar
        </div>
      </Row>
      {loading && (
        <FaSpinner
          style={{ marginTop: "10px" }}
          size={20}
          className={`icon-spin loader-blue`}
        />
      )}
    </div>
  );
};

export default NpeStep2Unique;
