import React, { useState, useEffect } from "react";
import { Row, Button, Modal } from "react-onsenui";
// import ProgressBar from "../../components/ProgressBar";
import chivoIcon from "../../assets/images/chivo_icon.png";
import bitcoinIcon from "../../assets/images/btc_icon.png";
import bitcoinLnIcon from "../../assets/images/btcln_icon.png";
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import {
  accountIdSelector,
  userIdSelector,
  qrCodeNameSelector,
  userFullNameSelector,
  userIsMerchantSelector
} from "../../redux/selectors";
import { createPayment } from "../../redux/ProcessPayment/processPayment.actions";
import { CREATE_PAYMENT } from "../../redux/ProcessPayment/processPayment.types";
import formatNumber, { formatUSDNumber } from "../../utils/formatNumber";
import { CONVERT_OPTIONS } from "../../constants";
import axios from "../../utils/Axios";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { logout, updateFiatConversion } from "../../redux/Auth/auth.actions";
import { useTranslation } from "react-i18next";

const Step3 = ({ history = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(true);
  const {
    user: { ConvertCurrency = CONVERT_OPTIONS.none }
  } = useSelector(state => state.auth);
  const payment = useSelector(state => state?.paymentMethod?.payment);
  const [msj] = useState({
    msj1: t("Se ha cambiado la configuración con éxito"),
    msj2: t("Ocurrió un error al cambiar la configuración")
  });
  const [convertChecked, setConvertChecked] = useState(
    payment.ProductId === 2
      ? ConvertCurrency === "USD"
      : ConvertCurrency === "BTC"
  );

  const userIsMerchant = useSelector(userIsMerchantSelector);

  const [
    initialConvertCurrencySetting,
    setInitialConvertCurrencySetting
  ] = useState(ConvertCurrency);

  const isLightning = useSelector(state => {
    return (
      state.paymentMethod.payment.lightning ||
      state.paymentMethod.payment.ProductNetwork === "lightning"
    );
  });

  const AccountId = useSelector(accountIdSelector);
  const Name = useSelector(qrCodeNameSelector);
  const FullName = useSelector(userFullNameSelector);
  const {
    user: { AvatarImage }
  } = useSelector(state => state.auth);

  const { payment: paymentData } = useSelector(state => state.paymentMethod);
  const [ref, setRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sizeQR, setSizeQr] = useState(1);
  const UserId = useSelector(userIdSelector);
  const [QRCODE, depositAddress] = useSelector(state => {
    if (!state.paymentMethod.payment) {
      return [false, false];
    }

    const { payment } = state.paymentMethod;

    let depositAddress = payment.ChargeDetail;
    let prefix = "";

    if (payment.ProductId === 2) {
      prefix = "bitcoin:";
      if (isLightning) {
        prefix = "";
      }
    } else {
      prefix = "usd:";
    }

    let amount = payment.Amount;
    if (typeof amount === "string") amount = parseFloat(amount);

    let assetAddress = prefix + depositAddress;
    if (!isLightning) {
      assetAddress += `?amount=${amount.toFixed(8)}`;
      assetAddress += `&message=${payment.InvoiceId}-${payment.NotionalAmount}`;
    }

    return [assetAddress, depositAddress];
  });
  console.log({ QRCODE });

  const revertConvert = async () => {
    if (userIsMerchant) {
      await axios.post(`${window.config.serverUri}SetUserProfile`, {
        UserId,
        AccountId,
        AvatarImage,
        Name,
        FullName,
        ConvertCurrency: initialConvertCurrencySetting,
        UserProfileType: "MerchantId"
      });
      dispatch(updateFiatConversion(initialConvertCurrencySetting));
    }
  };

  useEffect(
    () => {
      return history.listen(location => {
        revertConvert();
      });
    },
    [history]
  );

  function toggleLightning(payment) {
    axios
      .post(`${window.config.serverUri}CancelInvoice`, {
        InvoiceId: payment?.InvoiceId
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          dispatch(logout());
        }
      });

    try {
      dispatch(
        createPayment(
          {
            NotionalAmount: payment.NotionalAmount,
            Amount: payment.Amount,
            ProductId: payment.ProductId,
            lightning: !isLightning,
            UserId,
            AccountId
          },
          () => {}
        )
      );
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 404) {
        dispatch(logout());
      }
    }
  }

  const changeConversion = async () => {
    let option;
    if (convertChecked) {
      option = payment.ProductId === 2 ? "BTC" : "USD";
    } else {
      option = payment.ProductId === 2 ? "USD" : "BTC";
    }

    try {
      const { data } = await axios.post(
        `${window.config.serverUri}SetUserProfile`,
        {
          UserId,
          AccountId,
          AvatarImage,
          Name,
          FullName,
          ConvertCurrency: option,
          UserProfileType: "MerchantId"
        }
      );

      if (data.result) {
        setConvertChecked(!convertChecked);
        dispatch(updateFiatConversion(option));
        showAlert && NotificationManager.success(msj.msj1);
        setShowAlert(false);
      }
    } catch (error) {
      NotificationManager.error(msj.msj2);
    }
  };

  let minutes = 20;
  let seconds = 0;
  if (payment && payment.InvoiceStatus === "Pending") {
    try {
      const dateObj = new Date(payment.CreatedDT);
      const now = new Date();
      let diff = now - dateObj;
      if (diff > 0) {
        const timeInSeconds = diff / 1000;
        const elapsedMinutes = Math.ceil(timeInSeconds / 60);
        const elapsedSeconds = Math.floor(timeInSeconds % 60);

        minutes = Math.floor(20 - elapsedMinutes);
        seconds = Math.floor(60 - elapsedSeconds);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const [[mins, secs], setTime] = useState([minutes, seconds]);
  const [progress, setProgress] = useState(100);

  // useEffect(() => {
  //   if (isTracking) {
  //     const endTime = moment(payment.date).add(payment.waiting_time, "s");
  //     const timeLeft = moment.utc(endTime.diff(moment.now()));
  //     setTime([timeLeft.minutes(), timeLeft.seconds()]);
  //   }
  // }, [isTracking]);

  useEffect(
    () => {
      if (ref) {
        setSizeQr(0.8 * ref?.offsetWidth);
      }
    },
    [ref]
  );

  useEffect(
    () => {
      let timerId;
      timerId = setInterval(async () => {
        try {
          const { data } = await axios.post(
            `${window.config.serverUri}GetInvoice`,
            {
              InvoiceId: payment.InvoiceId
            }
          );

          if (data?.InvoiceStatus && data?.InvoiceStatus !== "Pending") {
            if (userIsMerchant) {
              const { data } = await axios.post(
                `${window.config.serverUri}SetUserProfile`,
                {
                  Name,
                  FullName,
                  UserId,
                  AccountId,
                  AvatarImage,
                  ConvertCurrency: initialConvertCurrencySetting,
                  UserProfileType: "MerchantId"
                }
              );

              if (data.result) {
                dispatch(updateFiatConversion(initialConvertCurrencySetting));
              }
            }

            return history.push({
              pathname: "/details",
              state: { movement: payment }
            });
          } else {
            /**
             * Handle Invoice initialized with:
             *  ChargeDetail: ""
             *  InvoiceStatus: "0"
             */
            dispatch(
              {
                type: CREATE_PAYMENT,
                payload: {
                  ...paymentData,
                  ...data
                }
              },
              () => {}
            );
          }
        } catch (error) {
          if (error?.response?.status === 404) {
            dispatch(logout());
          }
        }
      }, 3000);

      return () => clearInterval(timerId);
    },
    [payment.InvoiceId]
  );

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const tick = () => {
    if (mins === 0 && secs === 0) {
      setProgress(0);
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
      setProgress((mins * 60 * 100) / (5 * 60));
    } else {
      setTime([mins, secs - 1]);
      setProgress(((mins * 60 + secs) * 100) / (5 * 60));
    }
  };

  return (
    <div className="payment-step">
      <div className="message">
        {t("Seleccione el método de pago a utilizar")}
      </div>
      {payment.ProductId === 2 ? (
        <Row className="payment-methods payment-step3">
          <Row
            className={
              !isLightning ? "payment-options border-blue" : "payment-options"
            }
            onClick={() => {
              if (isLightning) {
                toggleLightning({
                  ...payment,
                  lightning: false
                });
              }
            }}>
            <img src={bitcoinIcon} alt="" className="payment-icon" />
            <div className="text-box">
              <div className="payment-title">Bitcoin (BTC)</div>
            </div>
          </Row>
          <Row
            className={
              isLightning ? "payment-options border-blue" : "payment-options"
            }
            onClick={() => {
              if (!isLightning) {
                toggleLightning({
                  ...payment,
                  lightning: true
                });
              }
            }}>
            <img src={bitcoinLnIcon} alt="" className="payment-icon" />
            <div className="text-box">
              <div className="payment-title">Bitcoin LN</div>
              <div className="payment-detail">(Lightning network)</div>
            </div>
          </Row>
        </Row>
      ) : (
        <Row className="payment-methods payment-step3">
          <Row className="payment-options border-blue payment-chivo">
            <img src={chivoIcon} alt="" className="payment-icon" />
            <div className="text-box">
              <div className="payment-title">Chivo</div>
              <div className="payment-detail">{t("Billetera")}</div>
            </div>
          </Row>
        </Row>
      )}
      <Row className="payment-methods payment-step3">
        <div className="info-box">
          <div className="total-send">{`$${formatUSDNumber(
            payment.NotionalAmount
          )}`}</div>
          <div className="currencyType-txt usd-title-bg">USD</div>
          <div className="timer">
            {/* <ProgressBar bgcolor="#6a1b9a" completed={progress} /> */}
            <div className="timer-number">
              {`${mins.toString().padStart(2, "0")}:${secs
                .toString()
                .padStart(2, "0")}`}
            </div>
          </div>
          {payment.ProductId === 2 && (
            <>
              <div className="text-send">{t("Monto a pagar en Bitcoin")}</div>
              <div className="amount-btc">
                {formatNumber(payment.Amount)} BTC
              </div>
            </>
          )}
          {userIsMerchant && (
            <Row className="switch-row">
              <div className="switch-text">
                {`${t("Convertir a")} ${
                  payment.ProductId === 2 ? t("Dólares") : "Bitcoin"
                }`}
              </div>
              <label className="switch-box">
                <input
                  id="convert-checkbox"
                  type="checkbox"
                  checked={convertChecked}
                  onChange={() => changeConversion()}
                />
                <div className="slider round" />
              </label>
            </Row>
          )}
        </div>
        <div className="qr-code-box" ref={data => setRef(data)}>
          <Modal isOpen={isOpen}>
            <div>
              <div className="padding-qr">
                {QRCODE && <QRCode value={QRCODE} size={600} />}
              </div>
              <Button
                className="back-blue option-step"
                onClick={() => setIsOpen(false)}
                style={{
                  cursor: "pointer",
                  width: "450px",
                  backgroundColor: "#010C4C",
                  marginTop: "10px"
                }}>
                {t("Cancelar")}
              </Button>
            </div>
          </Modal>
          <div className="padding-qr" onClick={() => setIsOpen(true)}>
            {QRCODE && <QRCode value={QRCODE} size={200} />}
          </div>
        </div>
      </Row>
      <Row className="row-btn">
        <div
          className="wallet-container"
          style={{ flexDirection: isLightning ? "column" : "row" }}>
          <Button
            id="btn-copy"
            style={{
              minWidth: "8.625rem",
              marginRight: "1rem",
              maxHeight: "45px",
              marginBottom: isLightning ? "1rem" : undefined
            }}
            className="-btn pointer btn-steps"
            onClick={() =>
              navigator.clipboard.writeText(depositAddress).then(() => {
                NotificationManager.success("Copiado Exitosamente!");
              })
            }>
            {t("Copiar")}
          </Button>
          <div className="wallet-txt">{t("Wallet:")}</div>
          <div
            className="number-wallet"
            style={{ userSelect: "text", wordBreak: "break-all" }}>
            {depositAddress}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Step3;
