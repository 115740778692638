/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import ToFriends from "./ToFriends";
import AddAmount from "./AddAmount";
import ConfirmSend from "./ConfirmSend";
import SuccessSend from "./SuccessSend";
import ScanQR from "./ScanQR";
import { Row } from "react-onsenui";

const SendMoney = () => {
  const [step, setStep] = useState("");
  const [amount, setAmount] = useState(0);
  return (
    <Row className="tofriend-content">
      {step === "addAmount" ? (
        <AddAmount setStep={setStep} amount={amount} setAmount={setAmount} />
      ) : step === "scanQR" ? (
        <ScanQR setStep={setStep} amount={amount} setAmount={setAmount} />
      ) : step === "confirm" ? (
        <ConfirmSend setStep={setStep} amount={amount} />
      ) : step === "success" ? (
        <SuccessSend setStep={setStep} amount={amount} />
      ) : (
        <ToFriends setStep={setStep} />
      )}
    </Row>
  );
};

export default SendMoney;
