import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Button,
  Icon,
  Select,
  Checkbox,
  Modal
} from "react-onsenui";
import { FaSpinner } from "react-icons/fa";
import DataTable from "react-data-table-component";
import axios from "../../utils/Axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { NotificationManager } from "react-notifications";
import { logout } from "../../redux/Auth/auth.actions";
import QRCode from "react-qr-code";
import { userIdSelector } from "../../redux/selectors";
import { useTranslation } from "react-i18next";
import { IoCogOutline } from "react-icons/io5";

const Terminals = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const [showToken, setShowToken] = useState(false);

  const [settingLoginCode, setSettingLoginCode] = useState(false);
  const [LoginCode, setLoginCode] = useState("");

  const [showAddUser, setShowAddUser] = useState(false);
  const [name, setName] = useState("");
  const [posType, setPosType] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingPosId, setEditingPosId] = useState(false);
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);

  const posTypeValues = {
    online: "Punto de venta",
    // posfisico: "Punto de venta",
    posportable: "Chivo POS",
    manager: "Gerente"
  };

  const passwordsMatch = () => {
    return password === passwordConfirm;
  };

  const showPasswordMatchingError = () => {
    return (
      passwordConfirm &&
      passwordConfirm.length >= password.length &&
      !passwordsMatch()
    );
  };

  const passwordTooShort = () => {
    return password && password.length < 4;
  };

  useEffect(
    () => {
      getTerminals();
    },
    [editingPosId]
  );

  const getTerminals = async () => {
    try {
      const { data: merchants } = await axios.get(
        `${window.config.merchantServerUri}GetMerchants?UserId=${userId}`
      );

      if (merchants && merchants.length) {
        const MerchantId = merchants[0].MerchantId;
        const { data: pos } = await axios.get(
          `${
            window.config.merchantServerUri
          }GetMerchantPOSUsers?MerchantId=${MerchantId}`
        );

        setData([...merchants, ...pos]);
        return;
      }

      setData(merchants);
      return;
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(
          t("Ocurrió un error al cargar los terminales")
        );
      }
    }
  };

  const onAddUser = async () => {
    const resetFields = () => {
      setName("");
      setPosType("");
      setPassword("");
      setPasswordConfirm("");
      setShowAddUser(false);
    };

    if (!loading) {
      setLoading(true);
      let datar;
      try {
        let MerchantId = 0;
        if (data && data.length) {
          MerchantId = data[0].MerchantId;
        }
        const res = await axios.post(
          `${window.config.merchantServerUri}CreateMerchantPOSUser`,
          {
            Description: "",
            LogoURL: "",
            LoginCode: password,
            MerchantId: MerchantId,
            MerchantPOSUserId: 0,
            Password: password,
            Username: name,
            IsActive: true
          }
        );
        datar = res.data;

        if (datar.result === false) {
          if (datar.detail === "Merchant username already in use") {
            NotificationManager.error(t("Este usuario ya existe"));
          } else {
            NotificationManager.error(
              t("Ocurrió un error al crear el usuario")
            );
          }
          return;
        }

        await getTerminals();

        if (posType === "posportable" || posType === "posfisico") {
          const { data } = await axios.post(
            `${window.config.merchantServerUri}AuthenticateMerchantPOSUser`,
            {
              UserName: name,
              MerchantId,
              MerchantPOSUserId: datar.MerchantPOSUserId,
              LoginCode: password
            }
          );

          setToken(data.SessionToken);
          setShowToken(true);
        }
        getTerminals();
        resetFields();
        NotificationManager.success(t("Se ha creado el usuario con éxito"));
      } catch (error) {
        console.log(error);
        if (error.response?.status === 404) {
          dispatch(logout());
        } else {
          const { status_code } = error.response.data.error;
          if (status_code === "5031") {
            NotificationManager.error(t("Este usuario ya existe"));
          } else {
            NotificationManager.error(
              t("Ocurrió un error al crear el usuario")
            );
          }
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onUpdateStatus = async row => {
    try {
      let id = row.ApexUserId;
      row.ApexUserId = 0;
      setEditingPosId(false);
      const { data } = await axios.post(
        `${window.config.merchantServerUri}UpdateMerchantPOSUser`,
        {
          ...row,
          IsActive: !row.IsActive
        }
      );
      setTimeout(() => {
        row.ApexUserId = id;
      }, 4000);
      setEditingPosId(true);
      if (data.result) {
        NotificationManager.success(
          `Se ha ${!row.IsActive ? "activo" : "inactivo"} el terminal con éxito`
        );
      } else {
        getTerminals();
        NotificationManager.error(
          t("Ocurrió un error al actualizar el usuario")
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(
          t("Ocurrió un error al actualizar el usuario")
        );
      }
    }
  };

  const onUpdateLoginCode = async (row, NewLoginCode) => {
    if (!NewLoginCode) return;

    try {
      const { data } = await axios.post(
        `${window.config.merchantServerUri}SetMerchantPOSUserPin`,
        {
          MerchantPOSUserId: row.MerchantPOSUserId,
          MerchantId: row.MerchantId,
          NewLoginCode
        }
      );

      if (data.result) {
        NotificationManager.success("Éxito");
        setLoginCode("");
        setSettingLoginCode(false);
      } else {
        NotificationManager.error("Ocurrió un error");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        dispatch(logout());
      } else {
        NotificationManager.error(
          t("Ocurrió un error al actualizar el usuario")
        );
      }
    }
  };

  const isNumber = value => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      return true;
    }

    return false;
  };

  const rows = data.filter(i => i.MerchantPOSUserId).map((row, i) => (
    <div className="terminals-form" key={i}>
      <Row className="form-row" style={{ minheight: "80px" }}>
        <Col width="20%">
          <div className="form-col-input">
            <div className="form-label">
              {t("Nombre")}
              {":"}
            </div>
            <div className="form-cell">
              {row.MerchantTaxIdentifier || row.Username}
            </div>
          </div>
        </Col>
        <Col width="18%">
          <div className="form-col-input">
            <div className="form-label">{t("Nombre de usuario:")}</div>
            <div className="form-cell">{row.MerchantName || row.Username}</div>
          </div>
        </Col>
        <Col width="18%">
          <div className="form-col-input">
            <div className="form-label">
              {t("Tipo")}
              {":"}
            </div>
            <div className="form-cell">{t(posTypeValues["online"])}</div>
          </div>
        </Col>
        <Col width="18%">
          <div className="form-col-input">
            <div className="status-label">
              {t("Estatus")}
              {":"}
            </div>
            {row.ApexUserId ? (
              <Checkbox
                modifier="noborder"
                className="status-icon"
                checked={row.IsActive}
                onChange={() => {
                  onUpdateStatus(row);
                }}
                disabled={row.ApexUserId === 0 || row.pos_type === "manager"}
              />
            ) : (
              <FaSpinner
                style={{ marginTop: "10px" }}
                size={20}
                className={`icon-spin loader-blue`}
              />
            )}
          </div>
        </Col>
        <Col width="18%">
          <div
            className="form-col-input action blue-action"
            onClick={() => setSettingLoginCode(row)}
            style={{
              color: "white",
              background: "#010C4C"
            }}>
            <div
              className="status-label"
              style={{
                display: "flex",
                marginTop: "13px",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <IoCogOutline style={{ marginRight: "10px" }} size={22} />
              {t("Cambiar Código")}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ));

  return (
    <div className="content terminals">
      <Modal isOpen={showToken}>
        <div className="card dialog-center">
          <div className="card__content flex-center flex-col">
            <>
              <h2 className="card__title">{t("Clave de acceso")}</h2>
              {t(
                "Escanea este código desde tu dispositivo físico para vincularlo."
              )}
              <QRCode value={token} size={200} className="mt-2" />
              <p>{token}</p>
              <Button
                className="button-blue inline-button text-align-center mt-2"
                onClick={() => setShowToken(false)}>
                {t("Ok")}
              </Button>
            </>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={settingLoginCode.MerchantPOSUserId}
        onPostShow={() => document.getElementById("set-login-code").focus()}>
        <div className="card dialog-center">
          <div className="card__content flex-center flex-col">
            <>
              <h2 className="card__title">{t("Cambiar Código")}</h2>
              <h3 className="card__title">{settingLoginCode.Username}</h3>
              <input
                id="set-login-code"
                className="form-input"
                type="password"
                maxLength={4}
                value={LoginCode}
                style={{ width: "40%" }}
                onChange={e => setLoginCode(e.target.value)}
              />
              <div style={{ display: "flex" }}>
                <Button
                  className="button-blue inline-button text-align-center mt-2"
                  onClick={() =>
                    onUpdateLoginCode(settingLoginCode, LoginCode)
                  }>
                  {t("Ok")}
                </Button>
                <Button
                  className="button-blue inline-button text-align-center mt-2"
                  onClick={() => setSettingLoginCode(false)}
                  style={{ cursor: "pointer" }}>
                  {t("Cancelar")}
                </Button>
              </div>
            </>
          </div>
        </div>
      </Modal>
      <div className="content-title flex-center">{t("Puntos de Venta")}</div>
      <DataTable
        pagination
        columns={[{ cell: row => row }]}
        data={rows}
        customStyles={{
          rows: {
            style: {
              border: "none !important"
            }
          },
          cells: {
            style: {
              display: "block"
            }
          },
          pagination: {
            style: {
              border: "none",
              paddingRight: "5vw",
              color: "black",
              fontSize: "15px"
            }
          }
        }}
        paginationComponentOptions={{
          rowsPerPageText: t("Filas por página"),
          rangeSeparatorText: t("de")
        }}
        noDataComponent={t("No hay terminales")}
        noTableHead
      />
      <Button
        className="button-blue button-add"
        onClick={() => setShowAddUser(!showAddUser)}>
        <Icon
          className="button-icon"
          size={{ default: 30, material: 30 }}
          icon="md-plus-circle"
        />
        {t("Agregar usuario")}
      </Button>
      {showAddUser && (
        <div className="terminals-form add-form">
          <Row className="form-row">
            <Col width="40%">
              <div className="form-col-input col-adduser">
                <div className="form-label">{`${t("Nombre")}:`}</div>
                <Input
                  className="form-input"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  maxLength={40}
                />
              </div>
            </Col>
            <Col width="40%">
              <div className="form-col-input col-adduser">
                <div className="form-label">{t("Tipo de punto de venta:")}</div>
                <Select
                  className="form-input"
                  value={posType}
                  onChange={e => setPosType(e.target.value)}>
                  {Object.keys(posTypeValues).map(key => {
                    if (key === "manager") return null;
                    return (
                      <option value={key} disabled={key === "posportable"}>
                        {posTypeValues[key]}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row className="form-row">
            <Col width="40%">
              <div className="form-col-input col-adduser">
                <div className="form-label">
                  {t("Contraseña")}
                  {":"}
                </div>
                <input
                  className="form-input"
                  type="password"
                  maxLength={4}
                  value={password}
                  onChange={e =>
                    isNumber(e.target.value) && setPassword(e.target.value)
                  }
                />
              </div>
            </Col>
            <Col width="40%">
              <div className="form-col-input col-adduser">
                <div className="form-label">{t("Repetir contraseña:")}</div>
                <input
                  className="form-input"
                  type="password"
                  maxLength={4}
                  value={passwordConfirm}
                  onChange={e =>
                    isNumber(e.target.value) &&
                    setPasswordConfirm(e.target.value)
                  }
                />
              </div>
            </Col>
          </Row>
          <div className="form-buttons">
            {showPasswordMatchingError() && (
              <p className="text-error">{t("Las contraseñas no coinciden")}</p>
            )}
            {passwordTooShort() && (
              <p className="text-error">{t("La contraseña es muy corta")}</p>
            )}
            <Button
              className="button-blue inline-button"
              onClick={onAddUser}
              disabled={
                loading ||
                !name ||
                !posType ||
                !(password && passwordsMatch()) ||
                passwordTooShort()
              }>
              {loading ? <Loader /> : "Agregar usuario"}
            </Button>
            <Button
              className="button-blue inline-button"
              onClick={() => {
                setName("");
                setPosType("");
                setPassword("");
                setPasswordConfirm("");
                setShowAddUser(false);
              }}>
              {t("Cancelar")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Terminals;
