import json2csv from 'json2csv'
import moment from 'moment';

export const saveBlob = async (filename, csvString) => {
  try {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    Promise.resolve();
  } catch (e) {
    console.error(e);
    Promise.reject(e);
  }
};

export const export2CSV = (data, name, transform = str => str, excelStrings = false) => {
  try {
    const date = moment(new Date()).format('DD-MM-YYYY');
  
    return new Promise((resolve, reject) =>
      json2csv({ data, excelStrings }, (error, csvString) => {
        if (error) return reject(error);

        let finalCsvString = csvString;
        if (transform) {
          finalCsvString = transform(csvString);
        }
        
        saveBlob(`${name} (${date}).csv`, finalCsvString);
        return resolve('Successfully exported data to CSV.');
      })
    );
  } catch (e) {
    console.log(e);
  }
}
