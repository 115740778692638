/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Col, Row, Switch } from "react-onsenui";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

const AddAmount = ({ amount, setStep, currencyBTC, setCurrencyBTC }) => {
  const { t } = useTranslation();
  const [deactivateConversion, setDeactivateConversion] = useState(true);
  return (
    <>
      <div className="title">{`Recibir en ${
        currencyBTC ? "Bitcoin" : "dólares"
      }`}</div>
      <Col className="input-col input-amount" width="100%">
        <Row className="row-size">
          <div className="info">
            {t("Escanea para recibir")}
            <span>${amount}</span>
          </div>
        </Row>
        <Row className="row-amount row-margin-top get-qr">
          <QRCode value="dasdasdsa" size={200} />
        </Row>
        <Row className="row-amount row-margin-top">
          <Switch
            checked={currencyBTC}
            onChange={() => setCurrencyBTC(!currencyBTC)}
          />
        </Row>
        {currencyBTC ? (
          <Row className="row-amount">
            <Switch
              className="convert"
              checked={deactivateConversion}
              onChange={() => setDeactivateConversion(!currencyBTC)}
            />
            <div className="switch-info">
              {t("Convertir automáticamente tus")}
              <strong>{t("BTC a USD")}</strong>
            </div>
          </Row>
        ) : null}
      </Col>
    </>
  );
};

export default AddAmount;
