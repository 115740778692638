import axios from "axios";

//  LOG  ["http://209.159.147.116:9591/api/billpay/GetCodeInfo?date=1648770385067"]


const urlpath2 = `http://209.159.147.116:9591/api/billpay/PayInvoice?date=${new Date().getTime()}`;

const getCodeInfo = async (body) => {
  const res = await fetch(`http://209.159.147.116:9591/api/billpay/GetCodeInfo?date=${new Date().getTime()}`, {
      headers: {
        aptoken: localStorage.getItem('SessionToken'),
        'Content-Type': 'application/json',
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(body),
    });

  return res.json()
};

const payInvoice = async (body) => {
  const res = await fetch(
    urlpath2,
    {
      headers: {
        aptoken: localStorage.getItem("SessionToken"),
        "Content-Type": "application/json",
      },
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(body),
    }
  );

  return res.json();
};

const GetUserIdentifiers = async () => {
    const res = await axios.post(`${window.config.serverUri}GetUserIdentifiers`, { UserId: localStorage.getItem("user_id") }, {
        headers: {
        aptoken: localStorage.getItem("merchantSessionToken"),
        },
    });
    
    return {
        status: res?.data?.errorcode || 200,
        statusText: res?.data?.detail || "",
        data: res?.data,
    };
} 

export { getCodeInfo, payInvoice, GetUserIdentifiers };
