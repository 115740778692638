import React from 'react'
import { Row } from 'react-onsenui'
import { FaArrowLeft } from 'react-icons/fa'

const Calculator = ({ handleState }) => {
  return (
    <div className="calculator">
    <Row>
      <div
        className="calculator-number top-left"
        onClick={() => handleState("1")}
        id="1"
      >
        1
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("2")}
        id="2"
      >
        2
      </div>
      <div
        className="calculator-number top-right"
        onClick={() => handleState("3")}
        id="3"
      >
        3
      </div>
    </Row>
    <Row>
      <div
        className="calculator-number"
        onClick={() => handleState("4")}
        id="4"
      >
        4
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("5")}
        id="5"
      >
        5
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("6")}
        id="6"
      >
        6
      </div>
    </Row>
    <Row>
      <div
        className="calculator-number"
        onClick={() => handleState("7")}
        id="7"
      >
        7
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("8")}
        id="8"
      >
        8
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("9")}
        id="9"
      >
        9
      </div>
    </Row>
    <Row>
      <div
        className="calculator-number bottom-left"
        onClick={() => handleState(".")}
        id="dot"
      >
        .
      </div>
      <div
        className="calculator-number"
        onClick={() => handleState("0")}
        id="0"
      >
        0
      </div>
      <div
        className="calculator-number bottom-right"
        onClick={() => handleState("-")}
        id="-"
      >
        <FaArrowLeft />
      </div>
    </Row>
  </div>
  )
}

export default Calculator