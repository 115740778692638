export const CONVERT_OPTIONS = {
  none: "none", //"do",
  usd: "USD", // "cu",
  btc: "BTC",
};

export const InvoiceStatuses = {
  Pending: 1,
  Paid: 2,
  Expired: 3,
  Canceled: 4,
  PartialPayment: 5  
};

export const invoiceStatusMapping = {
  "Pending": "En proceso",
  "Paid": "Aprobado",
  "Expired": "Rechazado",
  "Canceled": "Cancelado",
  "PartialPayment": "Pago no se ajusta a la factura",
};
