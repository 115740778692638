import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { formatUSDNumber } from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";

const ConfirmWithdraw = ({ history, payload, account }) => {
  const { t } = useTranslation();
  let selectedAccountData;
  if (account) {
    try {
      selectedAccountData = JSON.parse(account.AccountIdentifier);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="withdraw-card">
      <div className="withdraw-success-title">{t("Retiro realizado")}</div>
      <div className="withdraw-success-icon">
        <FaCheckCircle />
      </div>
      <div className="withdraw-confirm-title">{t("Retiraste")}</div>
      <div className="withdraw-amount button-blue">
        ${formatUSDNumber(payload.amount)} USD
      </div>
      <div className="withdraw-confirm-title">
        {t("A la cuenta terminada en")}
      </div>
      <div className="withdraw-confirm-account">
        ****{" "}
        {selectedAccountData?.DestinationAccountNumber?.slice(
          selectedAccountData?.DestinationAccountNumber?.length - 4,
          selectedAccountData?.DestinationAccountNumber?.length
        )}
      </div>
      <div className="withdraw-confirm-bank">{account?.Name}</div>
      <div
        className="withdraw-confirm-button withdraw-success-button action-btn pointer"
        onClick={() => history.push("/settings")}
      >
        {t("Ok")}
      </div>
    </div>
  );
};

export default ConfirmWithdraw;
